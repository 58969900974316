import {WS_URL} from "../AbstractService";

export default class NewsletterService {

    static async subscribe(email: string) {
        let formData = new FormData();
        formData.append('email', email);
        return await fetch(WS_URL + '/newsletter/subscribe', {
            method: 'post',
            body: formData,
        });
    }

    static async sendToAll(data: any) {
        return await fetch(WS_URL + '/newsletter/send-to-all', {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        });
    }

    static async send(data: any) {
        return await fetch(WS_URL + '/newsletter/send', {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        });
    }

    static async unsubscribe(email: string) {
        let formData = new FormData();
        formData.append('email', email);
        return await fetch(WS_URL + '/newsletter/unsubscribe', {
            method: 'delete',
            body: formData
        });
    }

    static async activate(email: string, verificationKey: string) {
        return await fetch(WS_URL + '/newsletter/activate?email=' + email + '&verificationKey=' + verificationKey, {
            method: 'get',
        });
    }
}