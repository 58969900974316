import * as React from "react";
import {useState} from "react";
import {useIntl} from "react-intl";
import LSForm from "../component/LSForm";
import ContentPage from "../component/Content";
import WebsiteNewsService from "../../../service/website/WebsiteNewsService";

interface Props {

}

const AddNewsPage: React.FC<Props> = (props) => {
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const intl = useIntl();

    const uiSchema = {
        "content": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 15
            }
        },
        "contentEnglish": {
            // "ui:help": "Hint: Make it strong!",
            "ui:widget": "textarea",
            "ui:options": {
                rows: 15
            }
        }
    };

    const schema: any = {
        "properties" : {
            "title" : {
                "title" : "Title",
                "readOnly" : false,
                "type" : "string"
            },
            "titleEnglish" : {
                "title" : "Title english",
                "readOnly" : false,
                "type" : "string"
            },

            "content" : {
                "title" : "Content",
                "readOnly" : false,
                "type" : "string"
            },
            "contentEnglish" : {
                "title" : "Content english",
                "readOnly" : false,
                "type" : "string"
            }
        },
        "required": ["title", "content", "titleEnglish", "contentEnglish"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        await WebsiteNewsService.saveNews(data.formData);
        window.document.location.href = "/news";
        setIsLoading(false);
    };


    return (
        <>
            <ContentPage title={"News hinzufügen"}
                         alert={alert}
                         hideContent={hideForm}
                         isLoading={isLoading}>
                <LSForm schema={schema}
                        hide={hideForm}
                        uiSchema={uiSchema}
                        submitButtonText={""}
                        onSubmit={(data) => onSubmit(data)}>
                </LSForm>
            </ContentPage>
        </>
    );
};

export default AddNewsPage;