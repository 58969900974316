// const response = await fetch('/api/groups');
// const body = await response.json();
import axios, {AxiosRequestConfig} from 'axios';
import LocalStorageService from "./LocalStorageService";
import {WS_URL} from "../AbstractService";
import Role from "../../model/Role";

export default class UserService {

    static logout() {
        LocalStorageService.deleteAuthentication();
        LocalStorageService.deletePrincipal();
    }

    static isAuthenticated() {
        return LocalStorageService.getAuthentication() != null;
    }

    static isAdmin() {
        const principal = LocalStorageService.loadPrincipal();
        if (principal == null || principal.roles == null) {
            return false;
        }
        var i;
        for (i = 0; i < principal.roles.length; i++) {
            if (principal.roles[i] === Role.ROLE_ADMIN) {
                return true;
            }
        }
        return false;
    }

    static async getAllUser(params: any) {
        let jsonResponse: any = {};

        try {
            let response = await axios(WS_URL + '/admin/users', {params: params});
            return response;
        } catch (e) {
            jsonResponse["status"] = e.response.status;
            return jsonResponse;
        }
    }

    static async getRoles() {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        return await fetch(WS_URL + '/users/roles', {
            method: 'get',
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });
    }

    static async addUser(data: any) {
        try {
            const response: any = await fetch(WS_URL + '/users/sign-up', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            });
            const jsonResponse = await response.json();
            jsonResponse["status"] = response.status;
            return jsonResponse;
        } catch (e) {
            return {};
        }
    }

    static async activate(data: any) {
        try {
            const response: any = await fetch(WS_URL + '/users/activate-user', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            });
            const jsonResponse = await response.json();
            jsonResponse["status"] = response.status;
            return jsonResponse;
        } catch (e) {
            return {};
        }
    }

    static async login(data: any) {
        let jsonResponse: any = {};
        try {
            const config: AxiosRequestConfig = {
                method: 'post',
                data: JSON.stringify(data),
                url: WS_URL + '/login'
            };
            let response = await axios(config);
            jsonResponse["status"] = response.status;
            if (response.status === 200) {
                LocalStorageService.saveAuthentication(response.headers.authorization);
            }
            return jsonResponse;
        } catch (e) {
            jsonResponse["status"] = e.response.status;
            return jsonResponse;
        }
    }

    static async getPrincipalData() {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        return await fetch(WS_URL + '/users/principal', {
            method: 'get',
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });
    }
}