import * as React from "react";
import ContentPage from "./component/Content";
import styles from './ImpressPage.module.scss';
import {useIntl} from "react-intl";

interface Props {

}

const ImpressPage: React.FC<Props> = (props) => {
    const intl = useIntl();
    return (
        <ContentPage title={intl.formatMessage({id: "ws-page-impress-title", defaultMessage: "Login"})}
                     metaTitle={intl.formatMessage({id: "ws-page-impress-title", defaultMessage: "Login"})}
                     metaDescription={intl.formatMessage({id: "ws-page-impress-description", defaultMessage: "Get access to our league and cup online services."})}
        >
            <p>
                <span className={styles.headline}>Firma</span><br />
                BM Solution - Kleingewerbe nach §19 Abs. 1 UStG<br />
                Benjamin Meyer
            </p>
            <p>
            <span className={styles.headline}>Kontakt</span>
                <br />
                Anschrift: Helene-Weber-Weg 15 - 50354 Hürth<br/>
                Telefon: 02233/7063806 (Kein Support)<br />
                E-Mail: webmaster@leaguescript.com<br />
            </p>
            <p>
            <span className={styles.headline}>Neukunden Support</span>
                <br />
                Möchtest du mit uns in Kontakt treten wollen, weil du über unsere Produkte informiert werden willst, so kannst du mit uns einen Chat starten.<br/>
                <a href={"https://tawk.to/chat/61df5ecdf7cf527e84d1da89/1fp88kl48"}>Jetzt Chat starten</a>
            </p>

            <p>
                <span className={styles.headline}>Steuerlicher Hinweis</span><br />
                Wir gelten nach nach §19 Abs. 1 UStG als Kleinunternehmen und sind somit nicht umsatzsteuerpflichtig. Demnach werden unsere Rechnungen ohne MwSt. ausgewiesen.
            </p>
            <br /> <br />
            <p>
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden https://ec.europa.eu/consumers/odr/. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
            </p>
        </ContentPage>
    );
};

export default ImpressPage;