const AUTHENTICATION = "authentication";
const PRINCIPAL = "principal";

export default class LocalStorageService {


    static saveAuthentication(bearer: string) {
        // TODO: Must be saved via http only cookie
        localStorage.setItem(AUTHENTICATION, bearer);
    }

    static savePrincipal(user: object) {
        localStorage.setItem(PRINCIPAL, JSON.stringify(user));
    }

    static loadPrincipal() {
        const principal = window.localStorage.getItem(PRINCIPAL);
        if (principal != null) {
            return JSON.parse(principal);
        }
        return null;
    }

    static getAuthentication() : string | null  {
        return localStorage.getItem(AUTHENTICATION);
    }

    static deleteAuthentication() : void  {
        return localStorage.removeItem(AUTHENTICATION);
    }

    static deletePrincipal() : void  {
        return localStorage.removeItem(PRINCIPAL);
    }
}