// const response = await fetch('/api/groups');
// const body = await response.json();

import {WS_URL} from "../AbstractService";

export default class ImprovementsService {

    static async send(data : any) {
        const response = await fetch(WS_URL + '/improvements', {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        jsonResponse["status"] = response.status;
        return jsonResponse;
    }

    static async get() {
        const response = await fetch(WS_URL + '/improvements', {
            method: 'get',
            headers: {'Content-Type' : 'application/json'},
        });
        const jsonResponse = await response.json();
        jsonResponse["status"] = response.status;
        return jsonResponse;
    }
}