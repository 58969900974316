import * as React from "react";
import {useEffect, useState} from "react";
import ContentPage from "../component/Content";
import 'moment/locale/de';
import {FormattedMessage, useIntl} from 'react-intl';
import AlertMessage from "../component/AlertMessage";
import Button from "@material-ui/core/Button/Button";
import LSForm from "../component/LSForm";
import ImprovementsService from "../../../service/website/ImprovementsService";
import {Improvement} from "../../../model/Improvement";
import Grid from "@material-ui/core/Grid";
import styles from "./ImprovementsPage.module.scss";
import ImprovementItem from "./ImprovementItem";
import moment from "moment";

interface Props {

}

const ImprovementsPage: React.FC<Props> = (props) => {
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [improvements, setImprovements] = useState<Array<Improvement>>([]);
    const [alert, setAlert] = useState<any>();
    const [isNew, setIsNew] = useState<boolean>(false);
    const intl = useIntl();

    const init = async () => {
        const improvementsResponse = await ImprovementsService.get();
        setImprovements(improvementsResponse);
    };

    useEffect(() => {
        init();
    }, []);

    const uiSchema = {
        "fromEMail": {
            "ui:widget": "email"
        },
        "description": {
            "ui:widget": "textarea"
        },
        "reason": {
            "ui:widget": "textarea"
        },
        "customerPriority": {
            "ui:placeholder": "1(small) - 10 (high)"
        }
    };


    const schema: any = {
        "properties": {
            "title": {
                "title": intl.formatMessage({id: "ws-page-improvements-title", defaultMessage: "Title"}),
                "type": "string"
            },
            "cost": {
                "title": intl.formatMessage({
                    id: "ws-page-improvements-cost",
                    defaultMessage: "What are you willed to pay for this feature?"
                }),
                "type": "string"
            },
            "description": {
                "title": intl.formatMessage({id: "ws-page-improvements-description", defaultMessage: "Description"}),
                "type": "string"
            },
            "reason": {
                "title": intl.formatMessage({id: "ws-page-improvements-reason", defaultMessage: "Reason"}),
                "type": "string"
            },
            "customerPriority": {
                "title": intl.formatMessage({id: "ws-page-improvements-customer_priority", defaultMessage: "Priority"}),
                "type": "number"
            },
            "fromEMail": {
                "title": intl.formatMessage({id: "ws-page-contact-from_email", defaultMessage: "E-Mail"}),
                "type": "string"
            }
        },
        "required": ["title", "fromEMail", "reason", "description", "cost", "customerPriority"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json: any = await ImprovementsService.send(data.formData);
        if (json.status === 200) {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-improvements-received_message",
                defaultMessage: "Thank you! We have received your improvement. You get an E-Mail when the imrpovement was approved."
            })} severity={"success"}/>);
            setHideForm(true)
        } else {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-improvements-error_message",
                defaultMessage: "We are sorry. An unexpected error occured."
            })} severity={"error"}/>);
        }
        setIsLoading(false);
    };


    return (
        <ContentPage title={"Improvements"}
                     headerImageUrl={"/img/website/header-images/idea.jpg"}
                     isLoading={isLoading}
                     metaTitle={intl.formatMessage({
                         id: "ws-page-improvements-title_page",
                         defaultMessage: "Improvements"
                     })}
                     metaDescription={intl.formatMessage({
                         id: "ws-page-improvements-description_page",
                         defaultMessage: "Send us your improvement wish."
                     })}
                     hideContent={isLoading}
                     alert={alert}>
            { !isNew &&
                <div style={{textAlign: "right"}}>
                    <Button type={"button"} variant="contained" color="primary" onClick={() => setIsNew(true)}>
                        <FormattedMessage id="ws-page-improvements-new_button" defaultMessage={"Send us your improvement wish"}/>
                    </Button>
                </div>
            }
            {isNew &&
                <LSForm schema={schema}
                        hide={hideForm}
                        uiSchema={uiSchema}
                        onSubmit={(data) => onSubmit(data)}>
                    <Button type={"submit"} variant="contained" color="primary">
                        <FormattedMessage id="ws-page-improvements-send_improvement" defaultMessage={"Send"}/>
                    </Button>
                </LSForm>
            }
            {!isNew &&
                <h1>
                    <FormattedMessage id="ws-page-improvements-improvements_headline"
                                      defaultMessage={"Given Improvements"}/>
                </h1>
            }
            {!isNew && improvements.map((improvement: Improvement, index: number) => {
                return <>
                <div className={styles.container}>
                    <Grid container spacing={2}>
                        <Grid key={2} item xs={3}>
                            <ImprovementItem improvementValue={improvement.title}
                                             label={<FormattedMessage id="ws-page-improvement-view-title" defaultMessage={"Title"}/>} />
                        </Grid>
                        <Grid key={3} item xs={3}>
                            <ImprovementItem improvementValue={improvement.customerPriority}
                                             label={<FormattedMessage id="ws-page-improvement-view-customer_prio" defaultMessage={"Customer Priority"}/>} />
                        </Grid>
                        <Grid key={4} item xs={3}>
                            <ImprovementItem improvementValue={improvement.priority}
                                             label={<FormattedMessage id="ws-page-improvement-view-prio" defaultMessage={"Our Priority"}/>} />
                        </Grid>
                        <Grid key={5} item xs={3}>
                            <ImprovementItem improvementValue={improvement.status}
                                             label={<FormattedMessage id="ws-page-improvement-view-status" defaultMessage={"Status:"}/>} />
                        </Grid>
                        <Grid key={6} item xs={3}>
                            <ImprovementItem improvementValue={improvement.progress}
                                             label={<FormattedMessage id="ws-page-improvement-view-progress" defaultMessage={"Progress:"}/>} />
                        </Grid>
                        <Grid key={7} item xs={3}>
                            <ImprovementItem improvementValue={improvement.started ? moment(improvement.started).locale("de").fromNow() : "-"}
                                             label={<FormattedMessage id="ws-page-improvement-view-started" defaultMessage={"Started"}/>} />
                        </Grid>
                        <Grid key={8} item xs={3}>
                            <ImprovementItem improvementValue={improvement.finished ? moment(improvement.finished).locale("de").fromNow() : "-"}
                                             label={<FormattedMessage id="ws-page-improvement-view-finished" defaultMessage={"Finished"}/>} />
                        </Grid>
                        <Grid key={8} item xs={3}>
                            <ImprovementItem improvementValue={moment(improvement.createdAt).locale("de").fromNow()}
                                             label={<FormattedMessage id="ws-page-improvement-view-created_at" defaultMessage={"Created"}/>} />
                        </Grid>
                        <Grid key={9} item xs={6}>
                            <ImprovementItem improvementValue={improvement.description}
                                             label={<FormattedMessage id="ws-page-improvement-view-description" defaultMessage={"Description"}/>} />
                        </Grid>
                        <Grid key={10} item xs={6}>
                            <ImprovementItem improvementValue={improvement.reason}
                                             label={<FormattedMessage id="ws-page-improvement-view-reason" defaultMessage={"Reason"}/>} />
                        </Grid>
                    </Grid>
                </div>
                <br/>
            </>
            })}
        </ContentPage>
    );
};

export default ImprovementsPage;