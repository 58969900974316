import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styles from "./FeaturesPage.module.scss";
import {setTitleAndDescription} from "../../../App";

interface Props {

}

const FeaturesPage = () => {
    let intl = useIntl();
    const backgroundStyle = (imageSrc: string) => {
        return {backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('" + imageSrc + "')"}
    }
    return (
        <>
            {
                setTitleAndDescription(
                    intl.formatMessage({id: "ws-page-feature-title", defaultMessage: "Features"}),
                    intl.formatMessage({id: "ws-page-feature-description", defaultMessage: "We have all features you need to create your own online league. E.g. automatic ranking calculation, 3 Point System, ELO Point System, Cup Tree and many more..."})
                )
            }
            <section className={styles.introBlock}>
                <h1><FormattedMessage id="ws-page-features-headline" defaultMessage={"Get the all in one solution for an online league"} /></h1>
                <ul>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/user-league.webp")}>
                            <p><FormattedMessage id="ws-page-features-user_league" defaultMessage={"User League"} /></p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/team-league.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-team_league" defaultMessage={"Team League"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/pointsystem.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-point_system" defaultMessage={"3 Point, ELO Point and Individual Point System"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/cuptree.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-cups" defaultMessage={"Cups with cuptree"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/news.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-news_area" defaultMessage={"News Area"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/match-detail.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-match_detail_view" defaultMessage={"Match Detail View"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/profile.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-user_profil" defaultMessage={"User Profil"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/teamprofile.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-team_profil" defaultMessage={"Team Profil"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/rating.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-ratings" defaultMessage={"Ratings of teams and profile"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/premium.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-premium_and_trusted_status" defaultMessage={"Premium and Trusted status"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/matchresult.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-match_result_entry" defaultMessage={"Easy Match Result Entry"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/privatemessage.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-send_and_receive_pm" defaultMessage={"Send and receive private messages"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/fixtures.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-fixtures" defaultMessage={"Fixtures"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/style.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-style_with_individual_images" defaultMessage={"Style with individual images"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={styles.imageContainer} style={backgroundStyle("/img/features/backend.webp")}>
                            <p>
                                <FormattedMessage id="ws-page-features-backend_easy" defaultMessage={"Easy to use backend"} />
                            </p>
                        </div>
                    </li>
                    <li>
                        <h2>
                            <FormattedMessage id="ws-page-features-many_more" defaultMessage={"AND MANY MORE ..."} />
                        </h2>
                    </li>
                </ul>
            </section>
        </>
    );
};

export default FeaturesPage;