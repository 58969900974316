import * as React from 'react';
import ContentPage from "../component/Content";
import UserService from "../../../service/website/UserService";

interface IndexPageProps {}

const IndexPage: React.FC<IndexPageProps> = (props) => {
  return (
    <ContentPage title={"Administration"}>
        <p>
            { UserService.isAdmin() &&
                <>
                    <a href={"/administration/user-list"}>Benutzerliste</a><br/>
                    <a href={"/administration/news"}>News</a><br/>
                    <a href={"/administration/send-newsletter"}>Newsletter versenden</a><br/>
                    <a href={"/administration/api-keys"}>ApiKeys</a><br/>
                </>
            }
        </p>
    </ContentPage>
  );
};

export default IndexPage;