import {useEffect} from 'react';
import {useHistory} from "react-router";

interface ScrollProps {

}

const ScrollToTop: React.FC<ScrollProps> = (props) => {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return (null);
};

export default ScrollToTop;