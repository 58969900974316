import * as React from "react";
import {useEffect, useState} from "react";
import ContentPage from "./component/Content";
import LSForm from "./component/LSForm";
import UserService from "../../service/website/UserService";
import AlertMessage from "./component/AlertMessage";
import {useIntl} from "react-intl";

interface Props {

}

const RegisterPage: React.FC<Props> = (props) => {
    const [extraErrors, setExtraErrors] = useState<any>([]);
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [disableForm, setDisableForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const intl = useIntl();

    const uiSchema = {
            "email": {
                // "ui:help": "Hint: Make it strong!",
                "ui:widget": "email"
            },
            "email2": {
                // "ui:help": "Hint: Make it strong!",
                "ui:widget": "email"
            },
            "password": {
                // "ui:help": "Hint: Make it strong!",
                "ui:widget": "password"
            },
            "password2": {
                // "ui:help": "Hint: Make it strong!",
                "ui:widget": "password"
            }

    };
    const schema : any = {
        "properties": {
            "email": {
                "title": intl.formatMessage({id : "ws-page-register-email", defaultMessage: "E-Mail"}),
                "type": "string",
                "minLength": 3
            },
            "email2": {
                "title": intl.formatMessage({id : "ws-page-register-email-repeat", defaultMessage: "Repeat E-Mail"}),
                "type": "string",
                "minLength": 3
            },
            "firstName": {
                "title": intl.formatMessage({id : "ws-page-register-first_name", defaultMessage: "First name"}),
                "type": "string",
                "minLength": 3
            },
            "lastName": {
                "title": intl.formatMessage({id : "ws-page-register-last_name", defaultMessage: "Last name"}),
                "type": "string",
                "minLength": 3
            },
            "password": {
                "title": intl.formatMessage({id : "ws-page-register-password", defaultMessage: "Password"}),
                "type": "string",
                "minLength": 6
            },
            "password2": {
                "title": intl.formatMessage({id : "ws-page-register-password-repeat", defaultMessage: "Password repeat"}),
                "type": "string",
                "minLength": 6
            },
            "accept": {
                "title": intl.formatMessage({id : "ws-page-register-privacy-notification", defaultMessage: "Agree to our privacy and terms of use"}),
                "type": "boolean"
            }
        },
        "messages": {
            required: intl.formatMessage({id : "ws-page-register-required", defaultMessage: "This value is required."}),
            minLength: intl.formatMessage({id : "ws-page-register-min_length", defaultMessage: "This field has not enough characters."})
        },
        "required": ["email", "password", "firstName", "lastName"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json = await UserService.addUser(data.formData);
        setExtraErrors(json.extraErrors);
        if (json.status === 200) {
            setHideForm(true);
            setAlert(<AlertMessage message={"Danke für deine Registrierung. Du erhälst in den nächsten Minuten eine E-Mail, in der du deinen Account bestätigen musst."} severity={"success"} />);
        } else if (json.status === 500) {
            setAlert(<AlertMessage message={"Es ist ein unerwarteter Fehler aufgetreten."} severity={"error"} />);
            setDisableForm(false);
        } else {
            setDisableForm(false);
        }
        setIsLoading(false);
    };

    const onError = (e: any) => {
        console.log(e);
    };

    const onValidate = (formData: any, errors: any) => {
        if (formData.password !== formData.password2) {
            errors.password2.addError(intl.formatMessage({id : "ws-page-register-pw_wrong", defaultMessage: "The passwords must be identically."}));
        }
        if (!formData.accept) {
            errors.accept.addError(intl.formatMessage({id : "ws-page-register-confirm-privacy_and_terms_of_use", defaultMessage: "Please confirm our privacy and terms of use."}));
        }
        return errors;
    };

    const transformErrors = (errors: any) => {
        return errors.map((error : any) => {
            // use error messages from JSON schema if any
            if (schema.messages && schema.messages[error.name]) {
                return {
                    ...error,
                    message: schema.messages[error.name]
                };
            }
            return error;
        });
    };

    useEffect(() => {
        setAlert(<AlertMessage message={intl.formatMessage({id : "ws-page-register-info_message", defaultMessage: "Register for free to get access to our services."})} severity={"info"}/>)
    },[]);

    return (
        <>
            <ContentPage title={intl.formatMessage({id : "ws-page-register-headline", defaultMessage: "Registration"})}
                         alert={alert}
                         hideContent={hideForm}
                         metaTitle={intl.formatMessage({id: "ws-page-register-title", defaultMessage: "Registration"})}
                         metaDescription={intl.formatMessage({id: "ws-page-register-description", defaultMessage: "Get started today with your own league and cup portal."})}
                         isLoading={isLoading}>
                <LSForm schema={schema}
                        hide={hideForm}
                        uiSchema={uiSchema}
                        validate={onValidate}
                        extraErrors={extraErrors}
                        columns={2}
                        spacing={3}
                        transformErrors={transformErrors}
                        disabled={disableForm}
                        onError={onError}
                        showErrorList={false}
                        submitButtonText={intl.formatMessage({id : "ws-page-register-submit_text", defaultMessage: "register"})}
                        onSubmit={(data) => onSubmit(data)}>
                </LSForm>
            </ContentPage>
        </>

    );
};

export default RegisterPage;