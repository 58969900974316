import * as React from "react";
import {useEffect, useState} from "react";
import ContentPage from "../component/Content";
import 'moment/locale/de';
import {useIntl} from 'react-intl';
import LSForm from "../component/LSForm";
import AlertMessage from "../component/AlertMessage";
import NewsletterService from "../../../service/website/NewsletterService";

interface Props {

}

const NewsLetterUnsubscribePage: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);

    const intl = useIntl();

    useEffect( () => {
    }, []);


    const schema: any = {
        "properties": {
            "email": {
                "title": intl.formatMessage({id: "ws-page-newsletter-unsubscribe-email", defaultMessage: "E-Mail"}),
                "type": "string"
            }
        },
        "required": ["email"]
    };

    const uiSchema = {
        "email": {
            "ui:widget": "email"
        }
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json: any = await NewsletterService.unsubscribe(data.formData.email);
        if (json.status === 200) {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-newsletter_unsubscribe-succesfully",
                defaultMessage: "Thank you for using our newsletter service. We have successfully removed your entry. You will not get any newsletter in the future."
            })} severity={"success"}/>);
            setHideForm(true)
        } else if (json.status === 404) {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-newsletter_unsubscribe-unkown",
                defaultMessage: "This E-Mail is unknown."
            })} severity={"error"}/>);
            setHideForm(true)
        } else {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-newsletter_unsubscribe-unkown-error-message",
                defaultMessage: "We are sorry. An unexpected error occured."
            })} severity={"error"}/>);
        }
        setIsLoading(false);
    };

    return (
        <ContentPage title={"Newsletter Logout"}
                     isLoading={isLoading}
                     alert={alert}
                     metaTitle={intl.formatMessage({id: "ws-page-newsletter-unsubscribe-title", defaultMessage: "Newsletter Logout"})}
                     metaDescription={intl.formatMessage({id: "ws-page-newsletter-unsubscribe-description", defaultMessage: "You don't want to get our Newsletter. Here you can unsubscribe."})}
                     hideContent={isLoading}>
            <p>Du möchtest keine Newsletter mehr bekommen. Hier kannst du dich austragen</p>
            <LSForm schema={schema}
                    hide={hideForm}
                    uiSchema={uiSchema}
                    submitButtonText={intl.formatMessage({id: "ws-page-newsletter-unsubscribe-unsubscribe_button", defaultMessage: "unsubscribe"})}
                    onSubmit={(data) => onSubmit(data)} />
        </ContentPage>
    );
};

export default NewsLetterUnsubscribePage;