import * as React from "react";
import {useEffect, useState} from "react";
import ContentPage from "../component/Content";
import 'moment/locale/de';
import {useIntl} from 'react-intl';
import LSForm from "../component/LSForm";
import AlertMessage from "../component/AlertMessage";
import NewsletterService from "../../../service/website/NewsletterService";

interface Props {

}

const NewsLetterPage: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);

    const intl = useIntl();

    useEffect( () => {
    }, []);


    const schema: any = {
        "properties": {
            "email": {
                "title": intl.formatMessage({id: "ws-page-contact-email", defaultMessage: "E-Mail"}),
                "type": "string"
            }
        },
        "required": ["email"]
    };

    const uiSchema = {
        "email": {
            "ui:widget": "email"
        }
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json: any = await NewsletterService.subscribe(data.formData.email);
        if (json.status === 200) {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-newsletter-received-message",
                defaultMessage: "Thank you! We send you an email. Please confirm your email address."
            })} severity={"success"}/>);
            setHideForm(true)
        } else if (json.status === 400) {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-newsletter-already_registered",
                defaultMessage: "This E-Mail is already registered."
            })} severity={"warning"}/>);
            setHideForm(true)
        } else {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-newsletter-error-message",
                defaultMessage: "We are sorry. An unexpected error occured."
            })} severity={"error"}/>);
        }
        setIsLoading(false);
    };

    return (
        <ContentPage title={"Newsletter"}
                     isLoading={isLoading}
                     alert={alert}
                     metaTitle={intl.formatMessage({id: "ws-page-newsletter-title", defaultMessage: "Newsletter"})}
                     metaDescription={intl.formatMessage({id: "ws-page-newsletter-description", defaultMessage: "Subscribe our newsletter."})}
                     hideContent={isLoading}>
            <LSForm schema={schema}
                    hide={hideForm}
                    uiSchema={uiSchema}
                    submitButtonText={intl.formatMessage({id: "ws-page-newsletter-register_button", defaultMessage: "subscribe"})}
                    onSubmit={(data) => onSubmit(data)} />
            <p>Informationen dazu, wie wir mit deinen Daten umgeht, findest du in unserer <a href={"/privacy"}>Datenschutzerklärung</a>. Du kannst dich jederzeit kostenfrei <a href={"/newsletter-unsubscribe"}>abmelden</a>.</p>
        </ContentPage>
    );
};

export default NewsLetterPage;