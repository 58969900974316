import * as React from "react";
import styles from './TopMenu.module.scss';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UserService from "../../../service/website/UserService";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {FormattedMessage} from "react-intl";
import {Hidden} from "@material-ui/core";

interface Props {
    onClickMenu: () => void
}

const TopMenu: React.FC<Props> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let onLogoutClick = () => {
        UserService.logout();
        document.location.href = "/login";
    };

    return (<>
        <section className={styles.background}>
            <span onClick={props.onClickMenu}>
                <MenuIcon fontSize="large"/>
            </span>
            <div>
                <Hidden mdDown>
                    <a href={"/"}>
                        <img src={"/img/main-logo.png"} className={styles.logo} />
                    </a>
                </Hidden>
            </div>
            <div className={styles.loginBackground} style={UserService.isAuthenticated() ? {} : {display: "none"}}>
                <span className={styles.account}>
                   <AccountCircleIcon/>
                    <span className={styles.loginUserDetails} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>Menü</span>
                </span>
            </div>
        </section>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            { UserService.isAdmin() &&
                <MenuItem onClick={() => document.location.href="/administration/index"}>
                    Administration
                </MenuItem>
            }
            { UserService.isAuthenticated() &&
                <MenuItem onClick={() => document.location.href="/api-key-management"}>
                    <FormattedMessage id="ws-component-topmenu-menu_entry_api_key" defaultMessage={"Api Key"}/>
                </MenuItem>
            }
            <MenuItem onClick={() => document.location.href="/change-pw"}>
                <FormattedMessage id="ws-component-topmenu-menu_change_password" defaultMessage={"Change password"}/>
            </MenuItem>
            <MenuItem onClick={onLogoutClick}><FormattedMessage id="ws-component-topmenu-menu_entry_logout" defaultMessage={"Logout"}/></MenuItem>
        </Menu>
    </>);
};

export default TopMenu;