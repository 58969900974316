import {WS_URL} from "../AbstractService";
import LocalStorageService from "./LocalStorageService";

export default class ChangePwService {

    static async changePwService(data: any) {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        return await fetch(WS_URL + '/change-pw', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

}