import {WS_URL} from "../AbstractService";

export default class RecoverUserPwService {

    static async recoverUserPwService(data: any) {
        return await fetch(WS_URL + '/recover-pw', {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        });
    }

}