import * as React from 'react';
import {ChangeEvent} from 'react';
import styles from "./IndexPage.module.scss";
import {useIntl} from "react-intl";

interface LanguageDropDownProps {
    locale: string;
    onChange: (locale: string) => void;
}

const LanguageDropDown: React.FC<LanguageDropDownProps> = (props) => {
    const intl = useIntl();
    return (
        <select className={styles.languageDropDown} value={props.locale}
                onChange={(event: ChangeEvent<HTMLSelectElement>) => props.onChange(event.target.value)}>
            <option value={"de"}>
                {intl.formatMessage({id: "ws-page-index-language-german", defaultMessage: "German"})}
            </option>
            <option value={"en"}>
                {intl.formatMessage({id: "ws-page-index-language-english", defaultMessage: "English"})}
            </option>
            <option value={"fr"}>
                {intl.formatMessage({id: "ws-page-index-language-french", defaultMessage: "French"})}
            </option>
            <option value={"pl"}>
                {intl.formatMessage({id: "ws-page-index-language-polish", defaultMessage: "Polish"})}
            </option>
            <option value={"tr"}>
                {intl.formatMessage({id: "ws-page-index-language-turkish", defaultMessage: "Turkish"})}
            </option>
            <option value={"it"}>
                {intl.formatMessage({id: "ws-page-index-language-italian", defaultMessage: "Italian"})}
            </option>
            <option value={"es"}>
                {intl.formatMessage({id: "ws-page-index-language-spanish", defaultMessage: "Spanish"})}
            </option>
            <option value={"pt"}>
                {intl.formatMessage({id: "ws-page-index-language-portuguese", defaultMessage: "Portuguese"})}
            </option>
            <option value={"ru"}>
                {intl.formatMessage({id: "ws-page-index-language-russian", defaultMessage: "Russian"})}
            </option>
            <option value={"gr"}>
                {intl.formatMessage({id: "ws-page-index-language-greek", defaultMessage: "Greek"})}
            </option>
            <option value={"dk"}>
                {intl.formatMessage({id: "ws-page-index-language-danish", defaultMessage: "Danish"})}
            </option>
            <option value={"fi"}>
                {intl.formatMessage({id: "ws-page-index-language-finisch", defaultMessage: "Finisch"})}
            </option>
            <option value={"hr"}>
                {intl.formatMessage({id: "ws-page-index-language-croatian", defaultMessage: "Croatian"})}
            </option>
            <option value={"nl"}>
                {intl.formatMessage({id: "ws-page-index-language-dutch", defaultMessage: "Dutch"})}
            </option>
            <option value={"si"}>
                {intl.formatMessage({id: "ws-page-index-language-slovenian", defaultMessage: "Slovenian"})}
            </option>
            <option value={"cz"}>
                {intl.formatMessage({id: "ws-page-index-language-czech", defaultMessage: "Czech"})}
            </option>
            <option value={"hu"}>
                {intl.formatMessage({id: "ws-page-index-language-hungarian", defaultMessage: "Hungarian"})}
            </option>
        </select>
    );
};

export default LanguageDropDown;