import * as React from "react";
import {useEffect, useState} from "react";
import ContentPage from "../component/Content";
import 'moment/locale/de';
import {useIntl} from 'react-intl';
import AlertMessage from "../component/AlertMessage";
import NewsletterService from "../../../service/website/NewsletterService";

interface Props {

}

const NewsLetterActivationPage: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);

    const intl = useIntl();

    useEffect( () => {
        const email = new URLSearchParams(window.location.search).get("email");
        const key = new URLSearchParams(window.location.search).get("verificationKey");
        NewsletterService.activate(email!, key!)
        setAlert(<AlertMessage message={intl.formatMessage({
            id: "ws-page-newsletter-activation-success",
            defaultMessage: "Newsletter was activated successfully."
        })} severity={"success"}/>);
    }, []);


    return (
        <ContentPage title={"Newsletter"}
                     isLoading={isLoading}
                     alert={alert}
                     metaTitle={intl.formatMessage({id: "ws-page-newsletter-activation-title", defaultMessage: "Newsletter Activation"})}
                     metaDescription={intl.formatMessage({id: "ws-page-newsletter-activation-description", defaultMessage: "Newsletter activation."})}
                     hideContent={isLoading}>
        </ContentPage>
    );
};

export default NewsLetterActivationPage;