import * as React from "react";
import styles from './HeadlineStartsite.module.css';
import {nl2br} from "../../../util/Misc";

interface Props {
    title: string;
    subtitle: string;
    text: string;
    colorH1?: string;
    colorH2?: string;
}

const HeadlineStartsite: React.FC<Props> = (props) => {
    return (
        <>
            <div className={styles.headline}>
                <hr /><h1 style={{color: props.colorH1}}>{props.title}</h1><hr />
                <h2 style={{color: props.colorH2}}>{props.subtitle}</h2>
            </div>

            <p className={styles.text} dangerouslySetInnerHTML={{__html:nl2br(props.text)}}>
            </p>
        </>
    );
};

export default HeadlineStartsite;