import * as React from "react";
import styles from './StartsitePage.module.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import ScrollAnimation from 'react-animate-on-scroll';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button/Button";
import SliderLegend from "../component/SliderLegend";
import Headline1 from "./Headline1";
import FeatureBlock from "./FeatureBlock";
import {FormattedMessage, useIntl} from "react-intl";
import {Hidden} from "@material-ui/core";
import FeatureDescription from "./FeatureDescription";
import {setTitleAndDescription} from "../../../App";
import {useHistory} from "react-router-dom";

interface Props {

}


const StartsitePage: React.FC<Props> = (props) => {
    let intl = useIntl();
    let history = useHistory();
    const onClickItem = (index: any) => {
        switch (index) {
            case 6:
                window.location.href="/register";
                break;
            default:
                window.location.href="/cloud";
        }
    };

    return (<>
        {
            setTitleAndDescription(
                intl.formatMessage({id: "ws-page-index-title", defaultMessage: "Build your own PC, PS4, PS5, X-Box or real-life leagues and cups within minutes."}),
                intl.formatMessage({id: "ws-page-index-description", defaultMessage: "Create your own professionel online league. Different point systems. A mobile friendly view and much more is waiting for you."})
            )
        }
        <Carousel autoPlay={true} infiniteLoop={true} showStatus={false} onClickItem={onClickItem} showThumbs={false} >
            <div>
                <a href={"/cloud"}>
                    <img className={styles.img} src={"/img/website/slider/start1.webp"}  alt={"NEW! LEAGUESCRIPT IN THE CLOUD!"}/>
                    <SliderLegend text={<FormattedMessage id="ws-page-startsite-teaser1" defaultMessage={"NEW! LEAGUESCRIPT IN THE CLOUD!"} />} color={"#f60000"} degree={"5"} bottom={"30"}/>
                </a>
            </div>
            <div>
                <a href={"/cloud"}>
                    <img className={styles.img} src={"/img/website/slider/start2.webp"}  alt={"CREATE CUPS WITH UP TO 256 PARTICIPANTS!"}/>
                    <SliderLegend text={<FormattedMessage id="ws-page-startsite-teaser2" defaultMessage={"CREATE CUPS WITH UP TO 256 PARTICIPANTS!"} />} color={"#1e69b5"} degree={"0"} bottom={"30"}/>
                </a>
            </div>
            <div>
                <a href={"/cloud"}>
                    <img className={styles.img} src={"/img/website/slider/start3.webp"}  alt={"USER PROFIL AND CHAT MESSAGES"}/>
                    <SliderLegend text={<FormattedMessage id="ws-page-startsite-teaser3" defaultMessage={"USER PROFIL AND CHAT MESSAGES"} />} color={"#ffae01"} degree={"5"} bottom={"30"}/>
                </a>
            </div>
            <div>
                <a href={"/cloud"}>
                    <img className={styles.img} src={"/img/website/slider/start4.webp"}  alt={"EASY TO USE BACKEND!"}/>
                    <SliderLegend text={<FormattedMessage id="ws-page-startsite-teaser4" defaultMessage={"EASY TO USE BACKEND!"} />} color={"#a9009f"} degree={"0"} bottom={"30"}/>
                </a>
            </div>
            <div>
                <a href={"/cloud"}>
                    <img className={styles.img} src={"/img/website/slider/start6.webp"}  alt={"MATCH REPORT | NOTIFICATIONS | GAMEACCOUNTS"}/>
                    <SliderLegend text={<FormattedMessage id="ws-page-startsite-teaser5" defaultMessage={"MATCH REPORT | NOTIFICATIONS | GAMEACCOUNTS"} />} color={"#88d600"} degree={"0"} bottom={"30"}/>
                </a>
            </div>
            <div>
                <a href={"/cloud"}>
                    <img className={styles.img} src={"/img/website/slider/start7.webp"}  alt={"TRUSTED AND PREMIUM LEAGUES"}/>
                    <SliderLegend text={<FormattedMessage id="ws-page-startsite-teaser6" defaultMessage={"TRUSTED AND PREMIUM LEAGUES"} />} color={"#1e69b5"} degree={"0"} bottom={"30"}/>
                </a>
            </div>
            <div>
                <a href={"/register"}>
                    <img className={styles.img} src={"/img/website/slider/start5.webp"}  alt={"REGISTER NOW!"}/>
                    <SliderLegend text={<FormattedMessage id="ws-page-startsite-teaser7" defaultMessage={"REGISTER NOW!"} />} color={"#505050"} degree={"0"} bottom={"30"}/>
                </a>
            </div>
        </Carousel>
        <section className={styles.mainContent}>
            <br/>
            <hr /><Headline1 color={"#f60000"} text={intl.formatMessage({id: "ws-page-start-main_headline", defaultMessage: "CREATE YOUR OWN ONLINE LEAGUE, CUP OR LEAGUE PORTAL!"})}/><hr />
            <h2 style={{fontSize: "20px", backgroundColor: "black", padding: "10px"}}>
                <FormattedMessage id="ws-page-startsite-intro" defaultMessage={"Within minutes you are ready to start your own free or premium league."} />
            </h2>
            <p style={{color: "#1e69b5", fontWeight: "bold", marginTop: "30px"}}>
                <FormattedMessage id="ws-page-startsite-new_concept" defaultMessage={"Our new CLOUD concept."} />
            </p>
            <ul className={styles.features}>
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature1" defaultMessage={"Ready to go within minutes."} />}
                />
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature2" defaultMessage={"New development, with new codebase."} />}
                />
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature3" defaultMessage={"Automated Software-Updates"} />}
                />
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature4" defaultMessage={"Always secured via https"} />}
                />
            </ul>
            <p style={{color: "#1e69b5", fontWeight: "bold", marginTop: "30px"}}><FormattedMessage id="ws-page-startsite-as_usual" defaultMessage={"As usual"} /></p>
            <ul className={styles.features}>
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature5" defaultMessage={"No visible copyright."} />}
                />
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature6" defaultMessage={"No advertisement"} />}
                />
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature7" defaultMessage={"Development with new features"} />}
                />
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature8" defaultMessage={"Support for many languages"} />}
                />
                <FeatureDescription
                    formattedMessage={<FormattedMessage id="ws-page-startsite-feature9" defaultMessage={"Make money with your league"} />}
                />
            </ul>
        </section>
        <div className={styles.imageContainer}>
            <Hidden xsDown>
            <Grid item xs={12}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Grid container spacing={2}>
                        <Grid key={1} item xs>
                            <div onClick={() => history.push("/cloud/features")}>
                                <img className={styles.img} src={"/img/website/startsite/pls.webp"}  alt={"Cloud Features"}/>
                            </div>
                        </Grid>
                        <Grid key={2} item xs>
                            <p onClick={() => history.push("/cloud/features")}>
                                <b><FormattedMessage id="ws-page-startsite-free_cloud_solution_header" defaultMessage={"Free Cloud Solution"} /></b><br/>
                                <FormattedMessage id="ws-page-startsite-free_cloud_solution_text" defaultMessage={"Create your first free cloud league with a small set of features."} />
                            </p>
                        </Grid>
                        <Grid key={3} item xs>
                            <div onClick={() => history.push("/cloud/features")}>
                                <img className={styles.img} src={"/img/website/startsite/freeleague.webp"} alt={"Free League"}/>
                            </div>
                        </Grid>
                        <Grid key={4} item xs>
                            <p onClick={() => history.push("/cloud/features")}>
                            <b><FormattedMessage id="ws-page-startsite-premium_cloud_solution_header" defaultMessage={"Premium Cloud League Solution"} /></b><br/>
                                <FormattedMessage id="ws-page-startsite-premium_cloud_solution_text" defaultMessage={"All-in-one package with the biggest feature set."} />
                            </p>
                        </Grid>
                </Grid><br />
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Grid container spacing={2}>
                        <Grid key={1} item xs>
                            <div onClick={() => history.push("/cloud/features")}>
                                <img className={styles.img} src={"/img/website/startsite/cup.webp"} alt={"Cup"}/>
                            </div>
                        </Grid>
                        <Grid key={2} item xs>
                            <p onClick={() => history.push("/cloud")}>
                                <b><FormattedMessage id="ws-page-startsite-https_support_header" defaultMessage={"HTTPS Support"} /></b><br/>
                                <FormattedMessage id="ws-page-startsite-https_support_text" defaultMessage={"Get more infos about https and secured commmunication."} />
                            </p>
                        </Grid>
                        <Grid key={3} item xs>
                            <div onClick={() => history.push("/cloud/features")}>
                                <img className={styles.img} src={"/img/website/startsite/race.webp"}  alt={"Race"}/>
                            </div>
                        </Grid>
                        <Grid key={4} item xs>
                            <p onClick={() => history.push("/cloud")}>
                                <b><FormattedMessage id="ws-page-startsite-selfhosting_solution_header" defaultMessage={"Selfhosting Solution"} /></b><br/>
                                <FormattedMessage id="ws-page-startsite-selfhosting_solution_text" defaultMessage={"Host the league on your own webspace hoster."} />
                            </p>
                        </Grid>
                </Grid>
                </ScrollAnimation>
                <br/>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Grid container spacing={2}>
                        <Grid key={1} item xs>
                            <div>
                                <a href={"/cloud"}>
                                    <img alt={"Transfers"} className={styles.img} src={"/img/website/startsite/transfer.webp"} />
                                </a>
                            </div>
                        </Grid>
                        <Grid key={2} item xs>
                            <p onClick={() => history.push("/cloud")}>
                                <b><FormattedMessage id="ws-page-startsite-support_header" defaultMessage={"Support"} /></b><br/>
                                <FormattedMessage id="ws-page-startsite-support_text" defaultMessage={"Choose one of our support channels"} />
                            </p>
                        </Grid>
                        <Grid key={3} item xs>
                            <div onClick={() => history.push("/cloud")}>
                                <img className={styles.img} src={"/img/website/startsite/android.webp"}  alt={"Android"}/>
                            </div>
                        </Grid>
                        <Grid key={4} item xs>
                            <p onClick={() => history.push("/cloud")}>
                                <b><FormattedMessage id="ws-page-startsite-mobile_view_header" defaultMessage={"Mobile view"} /></b><br/>
                                <FormattedMessage id="ws-page-startsite-mobile_view_text" defaultMessage={"The league in your pocket. Use your phone camera to take photos and add them to match reports."} />
                            </p>
                        </Grid>
                </Grid>
                </ScrollAnimation>
            </Grid>
            </Hidden>
        </div>
        <br/>
        <br/>
        <br/>
        <FeatureBlock backgroundColor={"#1e69b5"}
                      title={intl.formatMessage({id: "ws-page-startsite-realize_your_web_league_title", defaultMessage: "Realize your web league."})}
                      subtitle={intl.formatMessage({id: "ws-page-startsite-realize_your_web_league_subtitle", defaultMessage: "Create team or user leagues."})}
                      colorH1={"white"}
                      colorH2={"white"}
                      text={intl.formatMessage({id: "ws-page-startsite-realize_your_web_league_text", defaultMessage: "You can create user or team leagues. Define the point system, e.g. 3 point or elo points system."})}
                      imageUrl={"/img/rank.webp"} />
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div className={styles.productBox}>
                <img src={"/img/premium-player.webp"} alt={"Premium Player"}/>
                <img src={"/img/trusted-player.webp"} alt={"Trusted Player"}/>
                <br />
                <FormattedMessage id="ws-page-startsite-premium_and_trusted_play" defaultMessage={"Premium and Trusted Player"} />
            </div>
        </ScrollAnimation>
        <br/>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <FeatureBlock backgroundColor={"#a9009f"}
                              title={intl.formatMessage({id: "ws-page-startsite-fixtures_title", defaultMessage: "Fixtures"})}
                              subtitle={intl.formatMessage({id: "ws-page-startsite-fixtures_subtitle", defaultMessage: "With first and second half of league"})}
                              colorH1={"white"}
                              colorH2={"white"}
                              imageWidth={"95%"}
                              text={intl.formatMessage({id: "ws-page-startsite-fixtures_title", defaultMessage: "Fixtures"})}
                              imageUrl={"/img/matchplan.webp"} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FeatureBlock backgroundColor={"#ffae01"}
                              title={intl.formatMessage({id: "ws-page-startsite-notification_title", defaultMessage: "Notification"})}
                              subtitle={intl.formatMessage({id: "ws-page-startsite-notification_subtitle", defaultMessage: "Recognize if new matches wait for response"})}
                              colorH1={"white"}
                              colorH2={"white"}
                              imageWidth={"70%"}
                              text={intl.formatMessage({id: "ws-page-startsite-notification_text", defaultMessage: "You can see a notification in the header of the page if new matches or challenges are waiting for response."})}
                              imageUrl={"/img/notification.webp"} />

            </Grid>
        </Grid>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div className={styles.productBox}>
                <FormattedMessage id="ws-page-startsite-support_email_and_chat" defaultMessage={"Support via E-Mail and Chat"} />
            </div>
        </ScrollAnimation>
        <FeatureBlock backgroundColor={"#88d600"}
                      title={intl.formatMessage({id: "ws-page-startsite-cups_title", defaultMessage: "Cups"})}
                      subtitle={intl.formatMessage({id: "ws-page-startsite-cups_subtitle", defaultMessage: "Create cups with up to 256 participants."})}
                      colorH1={"white"}
                      colorH2={"white"}
                      text={intl.formatMessage({id: "ws-page-startsite-cups_text", defaultMessage: "Create small or big cups with up to 256 participants. The cup tree and matches are generated automatically."})}
                      imageUrl={"/img/cup.webp"} />
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div className={styles.productBox}>
                <FormattedMessage id="ws-page-startsite-since_2005" defaultMessage={"We develop this project since 2005"} />
            </div>
        </ScrollAnimation>
        {/*<div className={styles.productContainer}>*/}
        {/*    <div>*/}
        {/*        <p>*/}
        {/*            <CloudIcon style={{fontSize: "80px", color:"#f60000", marginRight: "30px"}}/>*/}
        {/*            <CloudIcon style={{fontSize: "80px", color:"#1e69b5", marginRight: "30px"}}/>*/}
        {/*            <CloudIcon style={{fontSize: "80px", color:"#ffae01", marginRight: "30px"}}/>*/}
        {/*            <CloudIcon style={{fontSize: "80px", color:"#a9009f", marginRight: "30px"}}/>*/}
        {/*            <CloudIcon style={{fontSize: "80px", color:"#88d600", marginRight: "30px"}}/>*/}
        {/*            <CloudIcon style={{fontSize: "80px", color:"#1e69b5", marginRight: "30px"}}/>*/}
        {/*            <CloudIcon style={{fontSize: "80px", color:"#505050", marginRight: "30px"}}/>*/}
        {/*        </p>*/}
        {/*        <p style={{color: "#888", fontSize: "24px"}}><FormattedMessage id="ws-page-startsite-cloud_packages" defaultMessage={"Our cloud packages"} /></p>*/}
        {/*    </div>*/}
        {/*    /!*<CloudPrice></CloudPrice>*!/*/}
        {/*    /!*<br/>*!/*/}
        {/*    /!*<p><Button variant="contained" color="primary" onClick={() => window.location.href="/cloud/price"}>*!/*/}
        {/*    /!*    <FormattedMessage id="ws-page-startsite-more_info" defaultMessage={"More information"} />*!/*/}
        {/*    /!*</Button></p>*!/*/}
        {/*</div>*/}
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div className={styles.productBox}>
                <img src={"/img/man.png"}  alt={"Fair"}/> <br />
                <FormattedMessage id="ws-page-startsite-fair_transparent" defaultMessage={"Fair and transparent."} />
            </div>
        </ScrollAnimation>
        <div className={styles.parallax}>
            <h1>Made with love<br /> - in germany -</h1>
        </div>
        
        <div className={styles.teaserPlsContainer}>
            <div>
                <h1><FormattedMessage id="ws-page-startsite-create_league_today" defaultMessage={"Create your own league today!"} /></h1>
                <p><FormattedMessage id="ws-page-startsite-create_league_today_subtext" defaultMessage={"Use our newest league management software in the cloud or as self hosting solution."} /></p>
                <Button variant="contained" color="primary" onClick={() => window.location.href="/cloud"}><FormattedMessage id="ws-page-startsite-create_league_today_start_now" defaultMessage={"START NOW"} /></Button>&nbsp;
                <Button variant="contained" color="primary" onClick={() => window.location.href="/cloud/features"}><FormattedMessage id="ws-page-startsite-features" defaultMessage={"FEATURES"} /></Button>&nbsp;
                <Button variant="contained" color="primary" onClick={() => window.location.href="https://cloud.leaguescript.com/?id=abc"}><FormattedMessage id="ws-page-startsite-create_league_today_demo" defaultMessage={"VIEW DEMO"} /></Button>
            </div>
        </div>
    </>);
};

export default StartsitePage;