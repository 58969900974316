import * as React from "react";
import {useState} from "react";
import ContentPage from "./component/Content";
import AlertMessage from "./component/AlertMessage";
import LSForm from "./component/LSForm";
import Button from "@material-ui/core/Button/Button";
import RecoverUserPwService from "../../service/website/RecoverUserPwService";
import {FormattedMessage, useIntl} from "react-intl";

interface Props {

}

const PasswordRecoveryPage: React.FC<Props> = (props) => {
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    let intl = useIntl();

    const uiSchema = {
        "email": {
            "ui:widget": "email"
        }
    };

    const schema : any = {
        "properties": {
            "email": {
                "title": intl.formatMessage({id: "ws-page-password-recovery-your_email", defaultMessage: "Your E-Mail"}),
                "type": "string"
            },
        },
        "required": ["email"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json : any = await RecoverUserPwService.recoverUserPwService(data.formData);
        if (json.status === 200) {
            setAlert(<AlertMessage message={intl.formatMessage({id: "ws-page-password-recovery-email_notification", defaultMessage: "You have received an e-mail with next steps."})} severity={"success"} />);
            setHideForm(true)
        } else if (json.status === 400) {
            setAlert(<AlertMessage message={intl.formatMessage({id: "ws-page-password-recovery-unknown_email", defaultMessage: "This e-mail is unknown."})} severity={"warning"} />);
        } else {
            setAlert(<AlertMessage message={intl.formatMessage({id: "ws-page-password-recovery-unexpected_error", defaultMessage: "An unexpected error accoured. Please try again later or contact us."})} severity={"error"} />);
        }
        setIsLoading(false);
    };


    return (
        <>
            <ContentPage title={intl.formatMessage({id: "ws-page-password-recovery-header", defaultMessage: "Password recovery"})}
                         alert={alert}
                         metaTitle={intl.formatMessage({id: "ws-page-password-recovery-title", defaultMessage: "Password Recovery"})}
                         metaDescription={intl.formatMessage({id: "ws-page-password-recovery-description", defaultMessage: "Forgot the password, then use this password recovery."})}
                         hideContent={hideForm}
                         isLoading={isLoading}>
                <LSForm schema={schema}
                        hide={hideForm}
                        spacing={8}
                        columns={1}
                        uiSchema={uiSchema}
                        onSubmit={(data) => onSubmit(data)}>
                    <Button type={"submit"} variant="contained" color="primary">
                        <FormattedMessage id="ws-page-password-recovery-new_password" defaultMessage={"Send Password"} />
                    </Button>
                </LSForm>
            </ContentPage>
        </>
    );
};

export default PasswordRecoveryPage;