import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {IntlProvider} from 'react-intl';
import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import messages_cz from "./translations/cz.json";
import messages_dk from "./translations/dk.json";
import messages_es from "./translations/es.json";
import messages_fi from "./translations/fi.json";
import messages_gr from "./translations/gr.json";
import messages_hr from "./translations/hr.json";
import messages_hu from "./translations/hu.json";
import messages_it from "./translations/it.json";
import messages_ja from "./translations/ja.json";
import messages_nl from "./translations/nl.json";
import messages_pl from "./translations/pl.json";
import messages_pt from "./translations/pt.json";
import messages_ru from "./translations/ru.json";
import messages_si from "./translations/si.json";
import messages_tr from "./translations/tr.json";
import CssBaseline from '@material-ui/core/CssBaseline';
import CookieConsent from "react-cookie-consent";
import IndexPage from "./pages/website/IndexPage";
import NotFoundPage from "./pages/general/404NotFound";
import Container from '@material-ui/core/Container';
import styles from "./pages/website/component/TopMenu.module.scss";
import {createTheme, Hidden, ThemeProvider} from "@material-ui/core";
import ScrollToTop from "./pages/general/ScrollToTop";

const messages = {
    'de': messages_de,
    'en': messages_en,
    'fr': messages_fr,
    'cz': messages_cz,
    'dk': messages_dk,
    'es': messages_es,
    'fi': messages_fi,
    'gr': messages_gr,
    'hr': messages_hr,
    'hu': messages_hu,
    'it': messages_it,
    'ja': messages_ja,
    'nl': messages_nl,
    'pl': messages_pl,
    'pt': messages_pt,
    'ru': messages_ru,
    'si': messages_si,
    'tr': messages_tr,
};

export function setTitleAndDescription(title?: string | null, description?: string | null) {
    if (title) {
        document.title = title;
    }
    if (description) {
        const selector = document.querySelector('meta[name="description"]');
        if (selector) {
            selector.setAttribute("content", description);
        }
    }
}

function AppRouter() {
    const [locale, setLocale] = useState<string>("de");

    const theme = createTheme({
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'filled',
                },
            },
        },
    })

    useEffect(() => {
        const locale = localStorage.getItem("locale");
        if (locale) {
            setLocale(locale);
            document.documentElement.setAttribute("lang", locale);
        } else {
            document.documentElement.setAttribute("lang", window.navigator.language);
        }
    }, []);

    return (
         //@ts-ignore
        <IntlProvider locale={locale} messages={messages[locale]}>
            <ThemeProvider theme={theme}>
                <Hidden mdUp >
                    <div style={{textAlign: "center", "backgroundColor": "black"}}>
                        <a href={"/"}>
                            <img src={"/img/main-logo.png"} className={styles.logo}  alt={"Logo"}/>
                        </a>
                    </div>
                </Hidden>
                <CssBaseline />
                <Router>
                    <ScrollToTop />
                    <Container maxWidth="lg" disableGutters={true}>
                        <CookieConsent
                            location="bottom"
                            buttonText="Verstanden."
                            cookieName="cookieConsent"
                            style={{ background: "#2B373B" }}
                            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                            expires={150}
                        >
                            Diese Webseite verwendet cookies. Wenn Du auf der Seite weitersurfst stimmst Du der Cookie-Nutzung zu.
                        </CookieConsent>
                        <Switch>
                            <Route path="/404notfound" component={NotFoundPage}/>
                            {/*<Route path="/support" component={SupportPage}/>*/}
                            <Route path="/" component={() => <IndexPage locale={locale} onLocaleChange={(locale : string) => {setLocale(locale); document.documentElement.setAttribute("lang", locale);localStorage.setItem("locale", locale);}} />}/>
                        </Switch>
                    </Container>
                </Router>
            </ThemeProvider>
        </IntlProvider>
    );
}

export default AppRouter;