import * as React from "react";
import {useEffect, useState} from "react";
import ContentPage from "./component/Content";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "@material-ui/core/Button/Button";
import AmazonPayService from "../../service/website/AmazonPayService";
import PlanService from "../../service/website/PlanService";
import {Plan} from "../../model/Plan";

interface Props {

}

const CheckoutPage: React.FC<Props> = (props) => {
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [plan, setPlan] = useState<Plan>();
    const intl = useIntl();
    const urlParams = new URLSearchParams(window.location.search);

    const init = async () => {
        const planId = urlParams.get('planId');
        if (planId != null) {
            const original = await PlanService.get(planId);
            setPlan(original);
        }
    }

    useEffect( () => {
        init();
    }, []);

    const onFinishOrder = () => {
        const amazonCheckoutSessionId = urlParams.get('amazonCheckoutSessionId');
        AmazonPayService.finishCheckout(amazonCheckoutSessionId, plan?.planId).then(function(_url) {
            // @ts-ignore
            document.location.href = _url;
        })
    }

    return (
        <>
            <ContentPage title={intl.formatMessage({id : "ws-page-checkout-headline", defaultMessage: "Checkout"})}
                         hideContent={hideForm}
                         metaTitle={intl.formatMessage({id: "ws-page-checkout-title", defaultMessage: "Checkout"})}
                         metaDescription={intl.formatMessage({id: "ws-page-checkout-description", defaultMessage: "Pay for the subscription"})}
                         isLoading={isLoading}>
                <h1><FormattedMessage id="ws-page-checkout-basket_info" defaultMessage={"Your order:"} /></h1>
                <p>{plan?.productName}</p>
                <p>{plan?.frequencyValue} x {plan?.frequencyUnit}</p>
                <p>{plan?.pricePublic} EUR</p>
                <p>(<FormattedMessage id="ws-page-checkout-monthly_cancable" defaultMessage={"Cancel possible monthly"} />)</p>
                <div style={{textAlign: "right"}}>
                <Button type={"button"} variant="contained" color="primary" onClick={onFinishOrder}>
                    <FormattedMessage id="ws-page-checkout-cancel_order" defaultMessage={"Cancel order"}/>
                </Button>&nbsp;
                <Button type={"button"} variant="contained" color="primary" onClick={onFinishOrder}>
                    <FormattedMessage id="ws-page-checkout-order" defaultMessage={"Finish order"}/>
                </Button>
                </div>
            </ContentPage>
        </>

    );
};

export default CheckoutPage;