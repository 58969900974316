import * as React from "react";
import styles from './Footer.module.scss';
import {FormattedMessage} from "react-intl";

interface Props {
}

const Footer: React.FC<Props> = (props) => {
    return (<>
        <footer>
            <img src={"/img/main-logo.png"} className={styles.logo} />
            <hr className={styles.divider}/>
            <p className={styles.links}>
                <a href="/impress">
                    <FormattedMessage id="ws-component-footer-impress" defaultMessage={"Impress"} />
                </a> | <a href="/privacy"><FormattedMessage id="ws-component-footer-privacy" defaultMessage={"Privacy"} /></a> | <a href="/gtc">
                <FormattedMessage id="ws-component-footer-gtc" defaultMessage={"General Terms and Conditions"} /></a> | <a href="/contact">
                <FormattedMessage id="ws-component-footer-contact" defaultMessage={"Contact"} /></a>| <a href="/terms-of-use">
                <FormattedMessage id="ws-component-footer-terms_of_use" defaultMessage={"Terms of use"} /></a><br/>
                <span className={styles.copyright}>© <a href="http://www.leaguescript.com">www.leaguescript.com</a> 2005-2022</span>
            </p>
        </footer>
    </>);
};

export default Footer;