import * as React from 'react';
import {useEffect, useState} from 'react';
import ContentPage from "../component/Content";
import UserService from "../../../service/website/UserService";
import {User} from "../../../model/User";
import styles from "./UserListPage.module.scss";
import Pagination from '@mui/material/Pagination/Pagination';


interface Props {
}

const UserListPage: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userList, setUserList] = useState<Array<User>>([]);
    const [size, setSize] = useState<number>(25);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);

    const init = async (currentPage: number) => {
        setIsLoading(true);
        const res = await UserService.getAllUser({page: currentPage, size: size});
        setUserList(res.data.content);
        setTotalPages(res.data.totalPages);
        setIsLoading(false);
    }

    useEffect(() => {
        init(page-1);
    }, []);

    const handleChange = (event: any, value: number) => {
        setPage(value);
        init(value-1);
    };

    return (
        <ContentPage title={"Statistiken"}
                     isLoading={isLoading}>
            <table className={styles.statisticTable}>
                <tr>
                    <td>Registriert</td>
                    <td>Vorname</td>
                    <td>Nachname</td>
                    <td>E-Mail</td>
                </tr>
                { userList.map((user: User, index: number) => {
                    return <tr>
                        <td>{user.createdAt && user.createdAt}</td>
                        <td>{user.firstName && user.firstName}</td>
                        <td>{user.lastName && user.lastName}</td>
                        <td>{user.email && user.email}</td>
                    </tr>
                })}
            </table>
            <div className={styles.pagination}>
                <Pagination count={totalPages}
                            page={page}
                            onChange={handleChange}/></div>
        </ContentPage>
    );
};

export default UserListPage;
