import * as React from "react";
import ContentPage from "./component/Content";
import {useIntl} from "react-intl";

interface Props {

}

const TermsOfUsePage: React.FC<Props> = (props) => {
    const intl = useIntl();
    return (
        <ContentPage title={intl.formatMessage({id: "ws-page-terms-of-use-title", defaultMessage: "Terms of use"})}
                     metaTitle={intl.formatMessage({id: "ws-page-terms-of-use-title", defaultMessage: "Terms of use"})}
                     metaDescription={intl.formatMessage({id: "ws-page-terms-of-use-description", defaultMessage: "Read our terms of use."})}
        >
            <p>
                Es ist jedem Nutzer untersagt, beleidigende, verleumderische, drohende, rassistische, pornografische, gewalt- oder drogenverherrlichende oder sonstige gesetzeswidrige Äußerungen zu verbreiten, entsprechende Nicknames zu verwenden und Links auf solche Inhalte zu setzen.
            </p>
            <p>
            Dazu gehört auch:
            </p>
            <ul>
                <li>Inhalte, die verletzend für andere sind oder illegalen Zwecken dienen</li>
                <li>Propaganda für verfassungsfeindliche Organisationen</li>
            </ul>
            <p>
            Identitätstäuschung: Es ist nicht gestattet, sich als als Prominenter oder sonstige reale Person auszugeben und andere zu täuschen Jeder Nutzer ist verpflichtet, die bestehenden gesetzlichen Bestimmungen (z.B. Strafrecht, Jugendschutz, Markenrecht, Urheberrecht, Unlauterer Wettbewerb etc.) einzuhalten.
            </p>
            <p>
            Eine Nutzung für kommerzielle Zwecke oder Werbung ist nicht gestattet. Der Nutzer muss Urheber der verfassten Texte sein. Das Einstellen von externen Links, Telefon- und Faxnummern ist untersagt.
            </p>
            <p>
            Foren dienen der Kommunikation vieler und sollten nicht für persönliche Zwecke missbraucht werden. Persönliche Nachrichten gehören nicht hierher. Behandeln Sie andere Teilnehmer mit Höflichkeit und Respekt. Bleiben Sie auch bei Meinungsverschiedenheiten sachlich.
            </p>
            <p>
            <h1>Bei Zuwiderhandlung</h1>
            </p>
            <p>
            leaguescript.com überprüft in regelmäßigen Abständen das Forum und andere öffentliche Kommunikationsräume. leaguescript.com löscht alle Beiträge, die gegen straf- und presserechtliche Bestimmungen der Bundesrepublik Deutschland bzw. die guten Sitten verstoßen, sofern die Redaktion hiervon Kenntnis erhält. Des Weiteren behält sich leaguescript.com vor, Beiträge ohne Angabe von Gründen zu kürzen bzw. Beiträge mit Inhalten, die nicht themenbezogen sind, zu entfernen. Verstößt ein Nutzer gegen die o.g. Regeln, ist leaguescript.com berechtigt, die betreffenden Inhalte zu sperren, zu löschen und den Benutzer ohne Nennung von Gründen von der Nutzung auszuschließen. Zudem kann eine straf- und zivilrechtliche Verfolgung drohen. Bei Fragen oder Problemen wenden Sie sich bitte an info@leaguescript.com. leaguescript.com ist dazu berechtigt, jederzeit Foren und andere Kommunikationsräume ohne Vorankündigung einzustellen.
            </p>
            <p>
            <h1>Haftungsbeschränkung</h1>
            </p>
            <p>
            Diese Internetseite enthält Links, die zu externen Internetseiten von Dritten führen kann, auf deren Inhalte wir jedoch keinen Einfluss haben. Es ist daher nicht möglich, eine Gewähr für diese Inhalte zu tragen.
            Die Verantwortung dafür hat immer der jeweilige Anbieter/Betreiber der entsprechenden Internetseite.
            Die Kommunikation in den Foren findet in Echtzeit statt und wird von den Benutzern direkt veröffentlicht. Obwohl leaguescript.com sich bemüht, die öffentlichen Bereiche zu überwachen, kann eine umfassende kontinuierliche redaktionelle Überprüfung der Kommunikationsräume nicht gewährleistet werden. leaguescript.com übernimmt daher keine Haftung und Verantwortung für die Beiträge. Das Herunterladen oder der sonstige Erhalt von Inhalten im Zusammenhang mit dem Angebot erfolgt auf eigene Gefahr.
            </p>
            <p>
            leaguescript.com behält sich das Recht vor, die Nutzungsbedingungen jederzeit zu ändern. Auf die Änderung der Nutzungsbedingungen wird auf diesen Seiten hingewiesen.
            </p>
        </ContentPage>
    );
};

export default TermsOfUsePage;