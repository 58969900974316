import {WS_URL} from "../AbstractService";
import axios from "axios";

const { REACT_APP_WS_URL } = process.env;

export default class WebsiteNewsService {

    static async getAllNews(params: any) {
        // return await fetch(WS_URL + '/news/all', {
        //
        // });

        let jsonResponse :any = {};

        try {
            let response = await axios(WS_URL + '/news/all', {params: params});
            return response;
        } catch (e) {
            jsonResponse["status"] = e.response.status;
            return jsonResponse;
        }
    }

    static async saveNews(data : any) {
        const response = await fetch(WS_URL + '/news/', {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        jsonResponse["status"] = response.status;
        return jsonResponse;
    }



}