import * as React from "react";
import styles from './ImpressPage.module.scss';
import ContentPage from "./component/Content";
import {useIntl} from "react-intl";

interface Props {

}

const GtcPage: React.FC<Props> = (props) => {
    const intl = useIntl();
    return (
        <ContentPage title={intl.formatMessage({id: "ws-page-gtc-title", defaultMessage: "General Terms and Conditions"})}
                     metaTitle={intl.formatMessage({id: "ws-page-gtc-title", defaultMessage: "General Terms and Conditions"})}
                     metaDescription={intl.formatMessage({id: "ws-page-gtc-description", defaultMessage: "Read our General Terms and Conditions"})}
        >
            <div className={styles.block}>
                <h3>§ 1 Vorbemerkung</h3>
                <p>
                    Nachstehende Geschäfts- und Lieferbedingungen gelten für alle Geschäfte auf www.leaguescript.com, sowie allen Produkten von BM Solutions Inhaber Benjamin Meyer, soweit es sich um Verbraucher im Sinne des Zivilrechtes sowie um sonstige Käufer handelt (Beide werden im folgenden als "Käufer" bezeichnet). Abweichende Bestimmungen des Käufers werden nur dann Vertragsbestandteil, wenn dies vereinbart wird.
                </p>

                <h3>§ 2 Anwendungsbereich der AGB</h3>
                <p>
                    Diese AGB gelten für die Nutzung der Internetseiten: www.leaguescript.com sowie aller zu diesen
                    Domains gehörenden Unterdomains.
                    Mit der Registrierung (vgl. § 4) kommt zwischen www.leaguescript.com und dem jeweiligen Mitglied
                    ein Nutzungsvertrag zustande, dessen Inhalt sich nach diesen AGB richtet.
                    Die AGB entfalten unmittelbare Rechtswirkung allein zwischen den Betreibern
                    von www.leaguescript.com und den dem jeweiligen Mitglied. Unmittelbare Rechtswirkungen
                    und Ansprüche im Sinne eines Vertrages zugunsten Dritter oder eines Vertrages mit
                    Schutzwirkung für Dritte, ergeben sich aus diesen AGB nicht.
                    Soweit auf www.leaguescript.com zusätzliche Dienste und Funktionen angeboten werden,
                    gelten ergänzende Regelungen. Auf die ergänzenden Bedingungen wird vor der
                    Inanspruchnahme hingewiesen. Mit der Inanspruchnahme der Zusatzfunktion
                    erklärt das Mitglied sich mit den jeweiligen Bedingungen einverstanden.
                </p>

                <h3>§ 3 Registrierung</h3>
                <p>
                    Die im Rahmen der Registrierung anzugebenden Daten, haben vollständig und wahrheitsgemäß zu sein.
                    Wir nehmen den Datenschutz sehr ernst, weswegen wir zur Registrierung lediglich eine E-Mail Adresse benötigen.
                    Für die Registrierung wird mindestens eine E-Mail Adresse, ein Benutzername und ein Passwort benötigt, damit wir
                    alle Benutzer eindeutig identifizieren können.
                    Der Vorname und der Nachname sind optional und werden für die Anrede in E-Mails verwendet.
                    Das Mitglied hat Sorge zu tragen, dass die E-Mail Adresse stets auf dem aktuellen Stand gehalten werden.
                </p>

                <p>
                    Der im Rahmen der Registrierung zu wählende Accountname darf kein geltendes Recht,
                    die guten Sitten oder die Rechte Dritter verletzten. Er darf ferner nicht aus einer E-Mail-,
                    oder Internetadresse bestehen. Die Anmeldedaten, bestehend aus E-Mailadresse, Accountname und
                    Passwort, sind vor dem Zugriff unberechtigter Dritter zu schützen. Das Passwort sollte möglichst lang sein, aus Groß- und Kleinbuchstaben,
                    sowie Zahlen und Sonderzeichen versehen sein, damit eine möglichst hohe Passwortsicherheit erzielt wird.
                </p>

                <h3>§ 3 Nutzungsgebühren</h3>
                <p>
                    Die Registrierung als Mitglied von www.leaguescript.com ist kostenlos.
                </p>

                <h3>§ 4 Nutzungsvertrag</h3>
                <p>
                    Die Nutzung von www.leaguescript.com ist ohne Registrierung möglich. Bitte die <a href="/termsOfUse">Nutzungsbedingungen</a> beachten.
                </p>
                <p>
                    Die Angebote von www.leaguescript.com insbesondere des Online Shops sind freibleibend.
                    Bestellungen des Käufers sind ein Angebot an BM Solutions Inhaber Benjamin Meyer
                    zum Abschluss eines Kaufvertrages.
                    Der Vertrag kommt erst mit Zugang unserer Bestätigung beim Käufer oder
                    Durchführung der Lieferung zustande.
                </p>
                <p>
                    Mit dem Update des Produktkataloges im Online-Shop werden die alten Angebote der
                    Firma BM Solutions Inhaber Benjamin Meyer ungültig. Es gelten die Preise, die am Tag des
                    Bestelleingangs Gültigkeit haben.
                </p>
                <p>
                    Um Zugriff auf unsere Download-Sektion zu erhalten benötigt es einer Registrierung (vgl. $ 3).
                </p>

                <h3>§ 5 Versand</h3>
                <p>
                    Der Versand erfolgt digital, weswegen immer eine gültige E-Mail vorhanden sein muss. Der Versand ist für Sie kostenlos.
                </p>


                <h3>§ 6 Mahnungsgebühren</h3>
                <p>
                    Mahnungsgebühren in Folge von nicht Zahlung trägt der Käufer.
                    Hierzu gehören Einschreiben- und Verwaltungsgebühren.
                </p>

                <h3>§ 7 Elektronische Rechnung</h3>
                <p>
                    Sie stimmen zu, dass Sie alle Rechnungen elektronisch erhalten möchten. Elektronische Rechnungen werden per E-Mail im PDF Format übersandt.
                    Da alle Käufe automatisch über PayPal (www.paypal.de) abgewickelt werden, verwenden wir die dort hinterlegte E-Mail Adresse als Rechnungsadresse.
                </p>

                <h3>§ 8 Widerrufsrecht</h3>
                <p>
                    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
                    Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
                    Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (BM Solutions Inhaber Benjamin Meyer, Helene-Weber-Weg 15, 50354 Hürth, Tel.: 022337063806, E-Mail: info@leaguescript.com)
                    mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag
                    zu widerrufen, informieren.
                    Bitte beachten Sie, dass Ihr Widerruf mit dem Download unserer Softare erlischt.
                    <br />
                        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                        Widerrufsfrist absenden.
                </p>


                <h3>§ 9 Folgen des Widerrufs</h3>
                <p>
                    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist.
                    Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
                    wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.       <br/>
                    <br />
                        Der Widerruf ist ausgeschlossen, sobald dem Käufer das digitale Produkt zur Verfügung gestellt wurde,
                        z.B. durch das Versenden der digitalen Software oder Versenden des Zugriffslinks.
                </p>

                <h3>§ 10 Datenspeicherung und Kündigung</h3>
                <p>
                    Als Kleingewerbetreibender können wir keine Datenspeicherungsgarantie geben.
                    Es kann passieren, dass es zu Datenverlusten kommt, obwohl wir einen Backup-Prozess nutzen.
                    Zudem können wir zu jeder Zeit einen Vertrag einseitig kündigen, wenn dieser einen ungewöhnlich hohen Datenverkehr aufweist oder gegen unsere Nutzungsbedingungen verstößt.
                </p>

                <h3>§ 11 Gerichtsstand</h3>
                <p>
                    Liegen die Voraussetzungen für eine Gerichtsstandsvereinbarung vor
                    (der Käufer ist Kaufmann oder juristische Person des öffentlichen Rechts oder hat
                    keinen allgemeinen Gerichtsstand in Deutschland), so ist dieser Gerichtsstand
                    für alle Ansprüche der Vertragsparteien 50354 Hürth.
                    BM Solutions ist berechtig, solch einen Käufer nach Wahl auch an
                    dessen allgemeinen Gerichtsstand zu verklagen. Es wird vereinbart,
                    dass für die Vertragsbeziehung ausschließlich deutsches Recht maßgeblich
                    und die Geltung des UN-Kaufrechts ausgeschlossen ist. Sollten einzelne der
                    vorstehenden Bedingungen unwirksam sein, so wird hiervon die
                    Gültigkeit der übrigen Bedingungen nicht berührt.
                    Die Vertragspartner sind verpflichtet, eine unwirksame Bestimmung durch
                    eine neue zu ersetzen, die dem Sinn und Zweck der unwirksamen
                    wirtschaftlich möglichst nahe kommt.
                </p>
            </div>
        </ContentPage>
    );
};

export default GtcPage;