// const response = await fetch('/api/groups');
// const body = await response.json();

import {WS_URL} from "../AbstractService";
import LocalStorageService from "./LocalStorageService";

export default class AmazonPayService {

    static async finishCheckout(checkoutSession : string | null, planId: string | undefined) {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        if (checkoutSession && planId) {
            const data: any = {};
            data.amazonCheckoutSessionId = checkoutSession;
            data.planId = planId;
            const response = await fetch(WS_URL + '/amazon-pay-checkout', {
                method: 'post',
                headers: {'Content-Type' : 'application/json', 'Authorization': auth},
                body: JSON.stringify(data)
            });
            return await response.json();
        }
    }

    static async completeCheckout(checkoutSession : string | null, planId: string | undefined) {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        const data: any = {};
        data.amazonCheckoutSessionId = checkoutSession;
        data.planId = planId;
        const response = await fetch(WS_URL + '/amazon-pay-checkout/complete-checkout', {
            method: 'post',
            headers: {'Content-Type' : 'application/json', 'Authorization': auth},
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        jsonResponse["status"] = response.status;
        return jsonResponse;
    }
}