import React, {useState} from "react";
import styles from './IndexPage.module.scss';
import {Redirect, Route, Switch} from "react-router";
import TopMenu from "./component/TopMenu";
import SideMenu from "./component/SideMenu";
import StartsitePage from "./startsite/StartsitePage";
import NewsPage from "./NewsPage";
import ContactPage from "./ContactPage";
import RegisterPage from "./RegisterPage";
import DownloadsPage from "./DownloadsPage";
import GtcPage from "./GtcPage";
import PrivacyPage from "./Privacy";
import TermsOfUsePage from "./TermsOfUsePage";
import LoginPage from "./LoginPage";
import ImpressPage from "./ImpressPage";
import Footer from "./component/Footer";
import RecoverProductKeyPage from "./RecoverProductKeyPage";
import {useIntl} from "react-intl";
import ActivateUserPage from "./ActivateUserPage";
import PasswordRecoveryPage from "./PasswordRecoveryPage";
import ChangePasswordPage from "./ChangePasswordPage";
import IndexApiPage from "./cloud/IndexPage";
import ApiKeyManagementPage from "./profile/ApiKeyManagementPage";
import NewsAdministrationPage from "./admin/NewsAdministration";
import AddNewsPage from "./admin/AddNews";
import FinishedProfessionalSupportOrderPage from "./shop/FinishedProfessionalSupportOrderPage";
import UserListPage from "./admin/UserListPage";
import AdminIndexPage from "./admin/IndexPage";
import ApiKeysPage from "./admin/ApiKeysPage";
import LanguageDropDown from "./LanguageDropDown";
import NewsLetterPage from "./newsletter/NewsLetterPage";
import NewsLetterUnsubscribePage from "./newsletter/NewsLetterUnsubscribePage";
import NewsLetterActivationPage from "./newsletter/NewsLetterActivation";
import SendNewsletterPage from "./admin/SendNewsletterPage";
import SendContactMessageToApiKeyOwnerPage from "./admin/SendContactMessageToApiKeyOwnerPage";
import ImprovementsPage from "./improvements/ImprovementsPage";
import CheckoutPage from "./CheckoutPage";
import CheckoutResultPage from "./CheckoutResultPage";

interface Props {
    onLocaleChange: (locale: string) => void
    locale: string
}


const IndexPage: React.FC<Props> = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const intl = useIntl();
    const {formatMessage} = intl;

    function toggleMenu() {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        // @ts-ignore
        <>
            <LanguageDropDown locale={props.locale} onChange={(locale: string) => props.onLocaleChange(locale)} />
            <TopMenu onClickMenu={() => setIsMenuOpen(!isMenuOpen)}/>
            <SideMenu open={isMenuOpen} onClose={toggleMenu}/>
                <section className={styles.mainContent}>
                    <Switch>
                            <Route path="/cloud" component={IndexApiPage}/>
                            <Route path="/" exact component={StartsitePage}/>

                            <Switch>
                                <Route path="/administration/api-keys" component={ApiKeysPage} />
                                <Route path="/administration/index" component={AdminIndexPage}/>
                                <Route path="/administration/send-newsletter" component={SendNewsletterPage}/>
                                <Route path="/administration/user-list" component={UserListPage}/>
                                <Route path="/administration/news" component={NewsAdministrationPage}/>
                                <Route path="/administration/add-news" component={AddNewsPage}/>
                                <Route path="/recover-pls-productkey" component={RecoverProductKeyPage}/>
                                <Route path="/contact/send-to-owner/:apiKey" component={SendContactMessageToApiKeyOwnerPage}/>
                                <Route path="/contact" component={ContactPage}/>
                                <Route path="/improvements" component={ImprovementsPage}/>
                                <Route path="/register" component={RegisterPage}/>
                                <Route path="/checkout" component={CheckoutPage}/>
                                <Route path="/checkout-result" component={CheckoutResultPage}/>
                                <Route path="/downloads" component={DownloadsPage}/>
                                <Route path="/news" component={NewsPage}/>
                                <Route path="/finished-professional-support-order" component={FinishedProfessionalSupportOrderPage}/>
                                <Route path="/gtc" component={GtcPage}/>
                                <Route path="/privacy" component={PrivacyPage}/>
                                <Route path="/newsletter" component={NewsLetterPage}/>
                                <Route path="/newsletter-unsubscribe" component={NewsLetterUnsubscribePage}/>
                                <Route path="/terms-of-use" component={TermsOfUsePage}/>
                                <Route path="/login" component={LoginPage}/>
                                <Route path="/activate-user/:activationCode" component={ActivateUserPage}/>
                                <Route path="/newsletter-activation" component={NewsLetterActivationPage}/>
                                <Route path="/impress" component={ImpressPage}/>
                                <Route path="/pw-recovery" component={PasswordRecoveryPage}/>
                                <Route path="/api-key-management" component={ApiKeyManagementPage}/>
                                <Route path="/change-pw" component={ChangePasswordPage}/>
                                <Route render={() => <Redirect to="/404notfound" />} />
                            </Switch>
                    </Switch>
                </section>
            <Footer />
        </>
    );
};

export default IndexPage;