import * as React from "react";
import {useState} from "react";
import ContentPage from "./component/Content";
import LSForm from "./component/LSForm";
import ContactService from "../../service/website/ContactService";
import AlertMessage from "./component/AlertMessage";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "@material-ui/core/Button/Button";
import styles from "./ContactPage.module.scss";

interface Props {

}

const ContactPage: React.FC<Props> = (props) => {
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const intl = useIntl();
    const urlParams = new URLSearchParams(window.location.search);
    const domainRequestText = "### My Domain ###:\nhttp://www.yourdomain.com\n\n### HTTPS certificate: ###\n[ ] Yes, please register a https for me\n[ ] No, my domain is already configured with https\n\nMy API Key: "+ urlParams.get('apiKey');

    const uiSchema = {
        "email": {
            "ui:widget": "email"
        },
        "message": {
            "ui:widget": "textarea",
            "ui:options": {
                "rows": urlParams.get('domain') ? 13 : null
            }
        }
    };

    const schema: any = {
        "properties": {
            "firstName": {
                "title": intl.formatMessage({id: "ws-page-contact-firstname", defaultMessage: "First name"}),
                "type": "string"
            },
            "lastName": {
                "title": intl.formatMessage({id: "ws-page-contact-lastname", defaultMessage: "Last name"}),
                "type": "string"
            },
            "email": {
                "title": intl.formatMessage({id: "ws-page-contact-email", defaultMessage: "E-Mail"}),
                "type": "string"
            },
            "phone": {
                "title": intl.formatMessage({id: "ws-page-contact-phonenumber", defaultMessage: "Phone number"}),
                "type": "string"
            },
            "message": {
                "title": intl.formatMessage({id: "ws-page-contact-message", defaultMessage: "Message"}),
                "type": "string",
                "default": urlParams.get('domain') ? domainRequestText : ""
            },
        },
        "required": ["email", "message"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json: any = await ContactService.sendContactForm(data.formData);
        if (json.status === 200) {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-contact-received-message",
                defaultMessage: "Thank you! We have received your message. We will contact you in near future."
            })} severity={"success"}/>);
            setHideForm(true)
        } else {
            setAlert(<AlertMessage message={intl.formatMessage({
                id: "ws-page-contact-error-message",
                defaultMessage: "We are sorry. An unexpected error occured."
            })} severity={"error"}/>);
        }
        setIsLoading(false);
    };


    return (
        <>
            <ContentPage title={intl.formatMessage({
                                id: "ws-page-contact-headline",
                                defaultMessage: "Contact"
                            })}
                         headerImageUrl={"/img/website/header-images/contact-header.webp"}
                         alert={alert}
                         hideContent={hideForm}
                         metaTitle={intl.formatMessage({id: "ws-page-contact-title", defaultMessage: "Contact"})}
                         metaDescription={intl.formatMessage({id: "ws-page-contact-description", defaultMessage: "Use our contact form to contact us."})}
                         isLoading={isLoading}>
                <LSForm schema={schema}
                        hide={hideForm}
                        uiSchema={uiSchema}
                        onSubmit={(data) => onSubmit(data)}>
                    <a href={"/privacy"} className={styles.privacyLink}>
                        <FormattedMessage id="ws-page-contact-privacy" defaultMessage={"Privacy"}/>
                    </a>
                    <Button type={"submit"} variant="contained" color="primary">
                        <FormattedMessage id="ws-page-contact-sendmessage" defaultMessage={"Send message"}/>
                    </Button>
                </LSForm>
            </ContentPage>
        </>
    );
};

export default ContactPage;