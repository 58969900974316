import * as React from "react";
import {useState} from "react";
import ContentPage from "./component/Content";
import AlertMessage from "./component/AlertMessage";
import LSForm from "./component/LSForm";
import Button from "@material-ui/core/Button/Button";
import ChangePwService from "../../service/website/ChangePwService";

interface Props {

}

const ChangePasswordPage: React.FC<Props> = (props) => {
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const uiSchema = {
        "newPassword": {
            "ui:widget": "password"
        },
        "newPasswordRepeat": {
            "ui:widget": "password"
        },
        "oldPassword": {
            "ui:widget": "password"
        }
    };

    const schema : any = {
        "properties": {
            "newPassword": {
                "title": "Neues Passwort",
                "type": "string"
            },
            "newPasswordRepeat": {
                "title": "Neues Passwort wiederholen",
                "type": "string"
            },
            "oldPassword": {
                "title": "Altes Passwort",
                "type": "string"
            },
        },
        "required": ["newPassword", "newPasswordRepeat", "oldPassword"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json : any = await ChangePwService.changePwService(data.formData);
        if (json.status === 200) {
            setAlert(<AlertMessage message={"Passwort erfolgreich geändert."} severity={"success"} />);
            setHideForm(true)
        } else if (json.status === 400) {
            setAlert(<AlertMessage message={"Altes Passwort ist falsch."} severity={"warning"} />);
        } else {
            setAlert(<AlertMessage message={"Es ist ein unerwarteter Fehler aufgetreten."} severity={"error"} />);
        }
        setIsLoading(false);
    };

    const validate = (formData: any, errors: any) => {
        if (formData.newPassword !== formData.newPasswordRepeat) {
            errors.newPasswordRepeat.addError("Passwords don't match");
        }
        return errors;
    };

    return (
        <>
            <ContentPage title={"Change password"} alert={alert} hideContent={hideForm} isLoading={isLoading}>
                <LSForm schema={schema}
                        hide={hideForm}
                        spacing={1}
                        columns={1}
                        validate={validate}
                        uiSchema={uiSchema}
                        onSubmit={(data) => onSubmit(data)}>
                    <Button type={"submit"} variant="contained" color="primary">Password ändern</Button>
                </LSForm>
            </ContentPage>
        </>
    );
};

export default ChangePasswordPage;