import {WS_URL} from "../AbstractService";
import LocalStorageService from "./LocalStorageService";

export default class PlanService {

    static async get(planId : string) {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        const response = await fetch(WS_URL + '/plan?planId=' + planId, {
            method: 'get',
            headers: {'Content-Type' : 'application/json', 'Authorization': auth},
        });
        const jsonResponse = await response.json();
        jsonResponse["status"] = response.status;
        return jsonResponse;
    }
}