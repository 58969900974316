import * as React from "react";
import {useState} from "react";
import ContentPage from "./component/Content";
import AlertMessage from "./component/AlertMessage";
import LSForm from "./component/LSForm";
import Button from "@material-ui/core/Button/Button";
import UserService from "../../service/website/UserService";
import {FormattedMessage, useIntl} from "react-intl";
import LocalStorageService from "../../service/website/LocalStorageService";

interface Props {

}

const LoginPage: React.FC<Props> = (props) => {
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const intl = useIntl();


    const uiSchema = {
        "email": {
            "ui:widget": "email"
        },
        "password": {
            "ui:widget": "password"
        },
        "ui:options": {
            "semantic": {
                "wrapItem": true,
                "horizontalButtons": true
            }
        }
    };

    const schema : any = {
        "properties": {
            "email": {
                "title": intl.formatMessage({id : "ws-page-login-email", defaultMessage: "E-Mail"}),
                "type": "string"
            },
            "password": {
                "title": intl.formatMessage({id : "ws-page-login-pw", defaultMessage: "Password"}),
                "type": "string"
            }
        },
        "required": ["email", "password"]
    };

    const initRole = async () => {
        const rolesResponse = await UserService.getRoles();
        const roles: any = await rolesResponse.json();
        LocalStorageService.savePrincipal({roles: roles});
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json : any = await UserService.login(data.formData);
        if (json.status === 200) {
            setAlert(<AlertMessage message={intl.formatMessage({id : "ws-page-login-login_succesfull", defaultMessage: "Login succcessfull."})} severity={"success"} />);
            setHideForm(true);
            initRole();
            window.setTimeout(() => {
                window.document.location.href="/";
            }, 500);
        } else if (json.status === 401 || json.status == 403) {
            setAlert(<AlertMessage message={intl.formatMessage({id : "ws-page-login-user_and_password_wrong", defaultMessage: "Username or password wrong."})} severity={"warning"} />);
        } else {
            setAlert(<AlertMessage message={intl.formatMessage({id : "ws-page-login-unexpected_error", defaultMessage: "An unexpected error occured"})} severity={"error"} />);
        }
        setIsLoading(false);
    };


    return (
        <>
            <ContentPage title={"Login"}
                         headerImageUrl={"/img/website/header-images/login-header.webp"}
                         alert={alert}
                         hideContent={hideForm}
                         metaTitle={intl.formatMessage({id: "ws-page-login-title", defaultMessage: "Login"})}
                         metaDescription={intl.formatMessage({id: "ws-page-login-description", defaultMessage: "Get access to our league and cup online services."})}
                         isLoading={isLoading}>
                <LSForm schema={schema}
                        hide={hideForm}
                        spacing={1}
                        columns={2}
                        uiSchema={uiSchema}
                        onSubmit={(data) => onSubmit(data)}>
                    <Button type={"submit"} variant="contained" color="primary">
                        <FormattedMessage id="ws-page-login-logintext" defaultMessage={"Login"}/>
                    </Button>
                    &nbsp;<Button variant="contained" color="primary" onClick={() => document.location.href="/pw-recovery"}><FormattedMessage id="ws-page-login-forget-pw" defaultMessage={"Forgot password"} /></Button>
                    <p onClick={() => document.location.href="/register"}>
                        <a href={"/register"}><FormattedMessage id="ws-page-login-not_registered_question" defaultMessage={"No account? Register now?"}/></a>
                    </p>
                </LSForm>
            </ContentPage>
        </>
    );
};

export default LoginPage;