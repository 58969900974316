import * as React from "react";
import Alert from '@mui/material/Alert/Alert';
import {useIntl} from "react-intl";

interface Props {
}

const GlobalErrorAlertMessage: React.FC<Props> = (props) => {
    const intl = useIntl();
    return (
        <Alert variant="filled" severity={"error"}><span dangerouslySetInnerHTML={{__html: intl.formatMessage({id : "ws-component-globalErrorAlertMessage-error_message", defaultMessage: "We are sorry. An unexpected error occured."})}}></span></Alert>
    );
};

export default GlobalErrorAlertMessage;