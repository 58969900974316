import * as React from 'react';
import {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import styles from "./CloudPrice.module.scss";
import {FormattedMessage, useIntl} from "react-intl";
import UserService from "../../../service/website/UserService";
import {User} from "../../../model/User";
import ApiKeyService from "../../../service/website/ApiKeyService";

interface CloudPriceProps {
    withPrice?: boolean;
    apiKeyType?: string;
    apiKey?: string;

}

const CloudPrice: React.FC<CloudPriceProps> = (props) => {
    const intl = useIntl();
    const { REACT_APP_CHECKOUT_URL } = process.env;
    const { REACT_PLAN_ID } = process.env;

    const init = async () => {
        const userResponse = await UserService.getPrincipalData();
        const user: User = await userResponse.json();
        const apiKeyRes = await ApiKeyService.getApiKey();
        const apiKey = await apiKeyRes.json();

        // @ts-ignore
        window.paypal.Buttons({
            style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'subscribe'
            },
            createSubscription: function(data: any, actions: any) {
                return actions.subscription.create({
                    //plan_id: REACT_PLAN_ID,
                    plan_id: "P-86416589D6089610LMIGE7TY",
                    // email - apikey - planid
                    custom_id: user.email + '|' + apiKey.secretKey + "|" + "P-86416589D6089610LMIGE7TY"
                });
            },
            onApprove: function(data: any, actions: any) {
                // @ts-ignore
                window.document.location.href="https://www.leaguescript.com/checkout-result";
            },
            onCancel: function() {
                alert(intl.formatMessage({id: "ws-component-cloudPrice-payment_canceled_message", defaultMessage: "Payment canceled"}));
            }
        }).render('#paypal-button-container');
    }

    useEffect( () => {
        init();
    }, []);

    return (
        <Grid container spacing={1}>
            {props.withPrice &&
            <Grid item xs={12} style={{textAlign: "center"}}>
                <h1><FormattedMessage id="ws-page-startsite-basis-package-upgrade_headline"
                                      defaultMessage={"Plan upgrade"}/></h1>
            </Grid>
            }
            {!props.withPrice &&
            <Grid item xs={12} md={4}>
                <div className={styles.priceContainer}>
                    <p style={{fontWeight: "bold"}}>
                        <FormattedMessage id="ws-page-startsite-basis-package-headline"
                                          defaultMessage={"Basis Package"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-basis_package_webspace"
                                          defaultMessage={"200 MB Webspace"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-basis_package_create_cup"
                                          defaultMessage={"Create 1 Cup"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-basis_package_create_league"
                                          defaultMessage={"Create 1 League"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-basis_package_address"
                                          defaultMessage={"cloud.leaguescript.com address"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-basis_package_copyright_footer"
                                          defaultMessage={"Copyright wihtin the footer"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-basis_package_number_of_user"
                                          defaultMessage={"100 User"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-basis_package_advertisement"
                                          defaultMessage={"Ad-supported"}/>
                    </p>
                    <p style={{marginTop: "30px", fontWeight: "bold"}}>
                        <FormattedMessage id="ws-page-startsite-basis_package_free"
                                          defaultMessage={"Free of charge"}/><br/>
                    </p>
                </div>
            </Grid>
            }
            <Grid item xs={12} md={4}>
                <div className={styles.priceContainer}>
                    <p style={{fontWeight: "bold"}}>
                        <FormattedMessage id="ws-page-startsite-standard_package"
                                          defaultMessage={"STANDARD (recommended)"}/>
                    </p>
                    <p>1 GB Webspace</p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-standard_package_cups_amount"
                                          defaultMessage={"Cups as much as you like."}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-standard_package_league_amount"
                                          defaultMessage={"Leagues as much as you like."}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-standard_package_https_certificate"
                                          defaultMessage={"Free of charge HTTPS certificate"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-standard_package_no_visible_copyright"
                                          defaultMessage={"No visible copyright in the footer"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-standard_package_number_of_user"
                                          defaultMessage={"500 User"}/>
                    </p>
                    <p>&nbsp;</p>
                    <p style={{marginTop: "30px", fontWeight: "bold"}}>
                        <FormattedMessage id="ws-page-startsite-standard_package_price"
                                          defaultMessage={"Monthly 12,99 EUR"}/><br/>
                        {props.withPrice &&
                        <FormattedMessage id="ws-page-startsite-not_available"
                                          defaultMessage={"(Currently not available)"}/>
                        }
                    </p>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={styles.priceContainer}>
                    <p style={{fontWeight: "bold"}}>
                        <FormattedMessage id="ws-page-startsite-premium_package" defaultMessage={"PREMIUM"}/>
                    </p>
                    <p>1 GB Webspace</p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-premium_package_cups_amount"
                                          defaultMessage={"Cups as much as you like."}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-premium_package_leagues_amount"
                                          defaultMessage={"Leagues as much as you like."}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-premium_package_https_certificate"
                                          defaultMessage={"Free of charge HTTPS ceritificate"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-premium_package_no_visible_copyright"
                                          defaultMessage={"No visible copyright in the footer"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-premium_package_number_of_user"
                                          defaultMessage={"5.000 User (more on demand)"}/>
                    </p>
                    <p>
                        <FormattedMessage id="ws-page-startsite-premium_package_allowed_for_commercial_use"
                                          defaultMessage={"Commercial use allowed."}/>
                    </p>
                    <p style={{marginTop: "30px", fontWeight: "bold"}}>
                        <FormattedMessage id="ws-page-startsite-premium_package_price_special"
                                          defaultMessage={"Special: Monthly 9,99 EUR"}/>
                        {props.withPrice &&
                        <>
                            <br/>
                            (<FormattedMessage id="ws-page-startsite-subscription_cancel" defaultMessage="Cancelation is possible every month." />)
                        </>
                        }
                    </p>
                    {props.withPrice &&
                    <p>
                        <div id="paypal-button-container"></div>
                    </p>
                    }
                </div>
            </Grid>
        </Grid>
    );
};

export default CloudPrice;