import * as React from "react";
import styles from './IndexPage.module.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Link from "@material-ui/core/Link/Link";
import CloudIcon from '@material-ui/icons/Cloud';
import Grid from "@material-ui/core/Grid/Grid";
import {Route, Switch} from "react-router";
import {FormattedMessage} from "react-intl";
import ApiAdvertisementPage from "./ApiAdvertisementPage";
import CreateApiKeyPage from "./CreateApiKeyPage";
import PricePage from "./PricePage";
import FeaturesPage from "./FeaturesPage";

interface Props {
}


const IndexPage: React.FC<Props> = (props) => {
    return (<>
        <section className={styles.menu}>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <span className={styles.cloudLogo}>
                        <a href={"/cloud"}>CLOUD Hosting</a> <CloudIcon/>
                    </span>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item md={3}>
                             <span className={styles.menuEntry}>
                                <Link href="#" onClick={() => window.document.location.href = "/cloud"}>
                                    <FormattedMessage id="ws-page-api-topmenu-get_started" defaultMessage={"Get started"}/>
                                </Link>
                             </span>
                        </Grid>
                        <Grid item md={3}>
                            <span className={styles.menuEntry}>
                                <Link href="#" onClick={() => window.document.location.href = "/cloud/features"}>
                                    <FormattedMessage id="ws-page-api-topmenu-features" defaultMessage={"Features"}/>
                                </Link>
                            </span>
                        </Grid>
                        <Grid item md={3}>
                            <span className={styles.menuEntry}>
                                <Link href="#" onClick={() => window.document.location.href = "/cloud/price"}>
                                    <FormattedMessage id="ws-page-api-topmenu-price" defaultMessage={"Price"}/>
                                </Link>
                            </span>
                        </Grid>
                        {/*<Grid item md={3}>*/}
                        {/*     <span className={styles.menuEntry}>*/}
                        {/*        <Link href="#" onClick={() => window.alert("We are doing our best, but currently this part is not accessable.")}>*/}
                        {/*            <FormattedMessage id="ws-page-api-topmenu-docu" defaultMessage={"Documentation"}/>*/}
                        {/*        </Link>*/}
                        {/*    </span>*/}
                        {/*</Grid>*/}
                        <Grid item md={3}>
                             <span className={styles.menuEntry}>
                                <Link href="#" onClick={() => window.document.location.href = "/contact"}>
                                    <FormattedMessage id="ws-page-api-topmenu-support" defaultMessage={"Support"}/>
                                </Link>
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
        <Switch>
            <Route path="/cloud/features" component={FeaturesPage}/>
            <Route path="/cloud/create-api-key" component={CreateApiKeyPage}/>
            <Route path="/cloud/price" component={PricePage}/>
            <Route path="/cloud" component={ApiAdvertisementPage}/>
        </Switch>
    </>);
};

export default IndexPage;