import * as React from 'react';
import TextColor from "./TextColor";

interface LeaguescriptHeadlineProps {
    product: string;
}

const LeaguescriptHeadline: React.FC<LeaguescriptHeadlineProps> = ({product}) => {
  return (
    <>
        <TextColor text={"l"} color={"#f60000"} />
        <TextColor text={"e"} color={"#1e69b5"} />
        <TextColor text={"a"} color={"#ffae01"} />
        <TextColor text={"g"} color={"#a9009f"} />
        <TextColor text={"u"} color={"#88d600"} />
        <TextColor text={"e"} color={"#1e69b5"} />
        <TextColor text={"s"} color={"#505050"} />
        <TextColor text={"c"} color={"#505050"} />
        <TextColor text={"r"} color={"#505050"} />
        <TextColor text={"i"} color={"#505050"} />
        <TextColor text={"p"} color={"#505050"} />
        <TextColor text={"t"} color={"#505050"} />
        &nbsp;<TextColor text={product} color={"#505050"} />
    </>
  );
};

export default LeaguescriptHeadline;