// const response = await fetch('/api/groups');
// const body = await response.json();

import {WS_URL} from "../AbstractService";

export default class ContactService {

    static async sendContactForm(data : any) {
        const response = await fetch(WS_URL + '/contact/', {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        jsonResponse["status"] = response.status;
        return jsonResponse;
    }

    static async sendMessageToApiKeyOwner(data : any) {
        const response = await fetch(WS_URL + '/contact/apikey-owner', {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        jsonResponse["status"] = response.status;
        return jsonResponse;
    }
}