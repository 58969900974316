import * as React from "react";
import {useEffect, useState} from "react";
import ContentPage from "./component/Content";
import WebsiteNewsService from "../../service/website/WebsiteNewsService";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styles from "./NewsPage.module.scss";
import moment from 'moment';
import 'moment/locale/de';
import Pagination from '@mui/material/Pagination/Pagination';
import {useIntl} from 'react-intl';

interface Props {

}

const NewsPage: React.FC<Props> = (props) => {
    const [size, setSize] = useState<number>(5);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [newsList, setNewsList] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const intl = useIntl();

    const initNews = async (currentPage: number) => {
        setIsLoading(true);
        const response = await WebsiteNewsService.getAllNews({page: currentPage, size: size});
        let newsList = await response.data.content;
        setTotalPages(response.data.totalPages);
        setNewsList(newsList);
        setIsLoading(false);
    };

    useEffect( () => {
        initNews(page-1);
    }, []);


    const onClick = () => {
       // appContext.loading.set(true);
    };

    const handleChange = (event: any, value: number) => {
        setPage(value);
        initNews(value-1);
    };

    return (
        <ContentPage title={"News"}
                     headerImageUrl={"/img/website/header-images/news_header.webp"}
                     isLoading={isLoading}
                     metaTitle={intl.formatMessage({id: "ws-page-news-title", defaultMessage: "News"})}
                     metaDescription={intl.formatMessage({id: "ws-page-news-description", defaultMessage: "Get all important updates about our services."})}
                     hideContent={isLoading}>
            {page != 1 &&
                <div className={styles.pagination}>
                <Pagination count={totalPages}
                            page={page}
                            onChange={handleChange}/></div>
            }
            { newsList.map((news: any, index: number) => {
                return <>
                    <Card>
                        <CardHeader
                            title={intl.locale === "de" ? news.title : news.titleEnglish}
                            subheader={moment(news.createdAt).locale("de").fromNow()}
                        />
                        {/*<CardMedia*/}
                            {/*image="/img/main-logo.png"*/}
                            {/*title="Paella dish"*/}
                        {/*/>*/}
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <div className={styles.content} dangerouslySetInnerHTML={{__html: intl.locale === "de" ? news.content : news.contentEnglish}}></div>
                            </Typography>
                        </CardContent>
                        {/*<div className={styles.actionButton} style={{display: news.content.length > 100 ? "block" : "none"}}>*/}
                            {/*<CardActions>*/}
                                {/*<Button variant="contained" color="primary">Mehr lesen</Button>*/}
                            {/*</CardActions>*/}
                        {/*</div>*/}
                    </Card><br/>
                </>
            })}
            <div className={styles.pagination}>
                <Pagination count={totalPages}
                            page={page}
                            onChange={handleChange}/></div>
        </ContentPage>
    );
};

export default NewsPage;