import * as React from "react";
import {useEffect, useState} from "react";
import ContentPage from "./component/Content";
import AlertMessage from "./component/AlertMessage";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "@material-ui/core/Button/Button";
import {Plan} from "../../model/Plan";

interface Props {

}

const CheckoutResultPage: React.FC<Props> = (props) => {
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [plan, setPlan] = useState<Plan>();
    const intl = useIntl();
    const urlParams = new URLSearchParams(window.location.search);

    const init = async () => {
        setAlert(<AlertMessage message={intl.formatMessage({id : "ws-page-checkoutResult-success", defaultMessage: "Payment finished. Api Key will be activated within the next minutes."})} severity={"success"} />)
    };

    useEffect( () => {
        init();
    }, []);

    return (
        <>
            <ContentPage title={intl.formatMessage({id : "ws-page-checkoutResult-headline", defaultMessage: "Checkout Result"})}
                         hideContent={hideForm}
                         alert={alert}
                         metaTitle={intl.formatMessage({id: "ws-page-checkoutResult-title", defaultMessage: "Checkout Result"})}
                         metaDescription={intl.formatMessage({id: "ws-page-checkoutResult-description", defaultMessage: "Payment finished."})}
                         isLoading={isLoading}>
                <div style={{textAlign: "right"}}>
                    <Button variant="contained" color="primary" onClick={() => document.location.href="/api-key-management"}><FormattedMessage id="ws-page-checkoutResult-check_api_key" defaultMessage={"Check API Key"} /></Button>
                </div>
            </ContentPage>
        </>

    );
};

export default CheckoutResultPage;