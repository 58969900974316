import * as React from 'react';

interface H1Props {
    text: string
    color: string;
}

const Headline1: React.FC<H1Props> = (props) => {
  return (
      <h1 style={{color: props.color,  fontSize: "30px"}}>{props.text}</h1>
  );
};

export default Headline1;