import * as React from "react";
import Alert from '@mui/material/Alert/Alert';

interface Props {
    message: string
    severity: 'error' | 'info' | 'success' | 'warning'
}

const AlertMessage: React.FC<Props> = (props) => {
    return (
        <Alert variant="filled" severity={props.severity}><span dangerouslySetInnerHTML={{__html: props.message}}></span></Alert>
    );
};

export default AlertMessage;