import * as React from 'react';
import styles from "./ImprovementsPage.module.scss";

interface ImprovementItemProps {
    label: React.ReactNode;
    improvementValue: string | number;
}

const ImprovementItem: React.FC<ImprovementItemProps> = ({improvementValue, label}) => {
  return (
      <div>
          <p className={styles.label}>{label}</p>
          <p>{improvementValue}</p>
      </div>
  );
};

export default ImprovementItem;