import * as React from 'react';
import {useEffect, useState} from 'react';
import ContentPage from "../component/Content";
import styles from "./ApiKeysPage.module.scss";
import ApiKeyService from "../../../service/website/ApiKeyService";
import {ApiKey} from "../../../model/ApiKey";

interface Props {
}

const ApiKeysPage: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [apiKeyList, setApiKeyList] = useState<Array<ApiKey>>([]);
    const [size, setSize] = useState<number>(25);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);

    const init = async (currentPage: number) => {
        setIsLoading(true);
        const response = await ApiKeyService.getApiKeys();
        const jsonResponse: any = await response.json();
        setApiKeyList(jsonResponse);
        setIsLoading(false);
    }

    useEffect(() => {
        init(page-1);
    }, []);

    const handleChange = (event: any, value: number) => {
        setPage(value);
        init(value-1);
    };

    return (
        <ContentPage title={"ApiKeys"}
                     isLoading={isLoading}>
            <table className={styles.statisticTable}>
                <tr>
                    <td>Key</td>
                    <td>Vorname</td>
                    <td>Nachname</td>
                    <td>E-Mail</td>
                    <td>Nächste Zahlung</td>
                    <td>Bezahlt bis</td>
                    <td>Aktionen</td>
                </tr>
                { apiKeyList.map((apiKey: ApiKey, index: number) => {
                    return <tr>
                        <td><a href={"https://cloud.leaguescript.com?id=" + apiKey.secretKey} target={"blank"}>{apiKey.secretKey}</a></td>
                        <td>{apiKey.owner && apiKey.owner.firstName && apiKey.owner.firstName}</td>
                        <td>{apiKey.owner && apiKey.owner.lastName && apiKey.owner.lastName}</td>
                        <td>{apiKey.owner && apiKey.owner.email && apiKey.owner.email}</td>
                        <td>{apiKey.owner && apiKey.nextBillingTime}</td>
                        <td>{apiKey.owner && apiKey.payedUntil}</td>
                        <td><a href={"/contact/send-to-owner/" + apiKey.secretKey}>Kontakt des ApiKeys anschreiben</a></td>
                    </tr>
                })}
            </table>
        </ContentPage>
    );
};

export default ApiKeysPage;
