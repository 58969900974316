import * as React from "react";
import {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import ContentPage from "../component/Content";
import AlertMessage from "../component/AlertMessage";
import ApiKeyService from "../../../service/website/ApiKeyService";
import UserService from "../../../service/website/UserService";
import LSForm from "../component/LSForm";

interface Props {

}

const CreateApiKeyPage: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [freeKeyExists, setFreeKeyExists] = useState<boolean>(true);
    const intl = useIntl();
    const authenticated = UserService.isAuthenticated();

    const uiSchema = {
        placeholder: {"ui:widget": "hidden"}
    };

    const schema: any = {
        "properties": {
            "firstName": {
                "title": intl.formatMessage({id: "ws-page-createApiKey-firstname", defaultMessage: "First name"}),
                "type": "string"
            },
            "lastName": {
                "title": intl.formatMessage({id: "ws-page-createApiKey-lastname", defaultMessage: "Last name"}),
                "type": "string"
            },
            "street": {
                "title": intl.formatMessage({id: "ws-page-createApiKey-street", defaultMessage: "Street"}),
                "type": "string"
            },
            "city": {
                "title": intl.formatMessage({id: "ws-page-createApiKey-city", defaultMessage: "City"}),
                "type": "string"
            },
            "zip": {
                "title": intl.formatMessage({id: "ws-page-createApiKey-zip", defaultMessage: "Postal code"}),
                "type": "string"
            },
            "country": {
                "title": intl.formatMessage({id: "ws-page-createApiKey-country", defaultMessage: "Country"}),
                "type": "string"
            },
            "phone": {
                "title": intl.formatMessage({id: "ws-page-createApiKey-phone", defaultMessage: "Phone number"}),
                "type": "string"
            }
        },
        "required": ["firstName", "lastName", "street", "city", "zip", "country", "phone"]
    };

    useEffect(() => {
        getApiKey();
        setIsLoading(false);
    }, []);

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        await ApiKeyService.requestApiKey(data.formData);
        setIsLoading(false);
        window.document.location.href="/api-key-management";
    };

    const getApiKey = async () => {
        const apiKeyResponse = await ApiKeyService.getApiKey();
        if (apiKeyResponse.status === 404) {
            setFreeKeyExists(false);
        }
    };


    const alert = !authenticated ? <AlertMessage message={"Du musst eingeloggt sein"} severity={"info"} /> : null;
    return (
        <>
            <ContentPage
                title={intl.formatMessage({id: "ws-page-createApiKey-headline", defaultMessage: "Request Api Key"})}
                alert={alert}
                metaTitle={intl.formatMessage({id: "ws-page-createApiKey-title", defaultMessage: "Create an Api Key"})}
                metaDescription={intl.formatMessage({id: "ws-page-createApiKey-description", defaultMessage: "To get access to all services, you need an Api Key."})}
                hideContent={authenticated ? false : true}
                isLoading={isLoading}>
                { !freeKeyExists &&
                    <>
                        <p>
                            <FormattedMessage id="ws-page-createApiKey-privacy_intro" defaultMessage="These form data will be saved to handle violations against our terms of use and internal uses. We will not pass on to third parties."/>
                        </p>
                        <LSForm schema={schema}
                                uiSchema={uiSchema}
                                columns={2}
                                spacing={3}
                                onSubmit={onSubmit}
                                submitButtonText={intl.formatMessage({id: "ws-page-createApiKey-create_free_api_key_button", defaultMessage: "Start Free Tier"})}

                        />
                        <p>
                            <a href={"/api-key-management"}>
                                <FormattedMessage id="ws-page-createApiKey-privacy_info_link" defaultMessage="Go to privacy information"/>
                            </a>
                        </p>
                    </>
                }
                { freeKeyExists &&
                    <p><FormattedMessage id="ws-page-createApiKey-api_key_exists" defaultMessage="You already have an API Key."/>
                        &nbsp;<a href={"/api-key-management"}>
                            <FormattedMessage id="ws-page-createApiKey-go2api_page" defaultMessage="Go to the API Key Management Page."/>
                        </a>
                    </p>
                }
            </ContentPage>
        </>
    );
};

export default CreateApiKeyPage;