import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import styles from "./ApiAdvertisementPage.module.scss";
import SpeedIcon from '@material-ui/icons/Speed';
import Grid from "@material-ui/core/Grid/Grid";
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import HttpsIcon from '@material-ui/icons/Https';
import BackupIcon from '@material-ui/icons/Backup';
import CloudIcon from '@material-ui/icons/Cloud';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Button from "@material-ui/core/Button/Button";
import {setTitleAndDescription} from "../../../App";

interface Props {

}

const ApiAdvertisementPage: React.FC<Props> = (props) => {
    let intl = useIntl();
    return (
        <>
            {
                setTitleAndDescription(
                    intl.formatMessage({id: "ws-page-apiAdvertisement-title", defaultMessage: "Your League in the cloud"}),
                    intl.formatMessage({id: "ws-page-apiAdvertisement-description", defaultMessage: "Register an account to get access to all cloud based league features."})
                )
            }
            <section className={styles.introBlock}>
                <h1><FormattedMessage id="ws-page-api-feature-headline" defaultMessage={"Your league in the cloud"}/></h1>
                <h2 style={{textAlign: "center"}}>
                    <FormattedMessage id="ws-page-api-feature-two_steps" defaultMessage={"Two easy steps"}/>
                </h2>
                <p>
                    <FormattedMessage id="ws-page-api-feature-step1" defaultMessage={"1. Register an account for free"}/><br/>
                    <FormattedMessage id="ws-page-api-feature-step2" defaultMessage={"2. Create a free of charge API Key"}/>
                    <br/>
                    <br/>
                    <span style={{fontWeight: "bold"}}>
                    <FormattedMessage id="ws-page-api-feature-access_to_league" defaultMessage={"Get access to your free of charge league, immediatly"}/>
                    <br/>
                    <FormattedMessage id="ws-page-api-feature-upgrade_message" defaultMessage={"A chargeable upgrade to the STANDARD or PREMIUM package is possible afterwards."}/></span>
                    {/*<FormattedMessage id="ws-page-api-start_text" defaultMessage={"Take your time "}/>*/}
                </p>
                <p>
                    <Button variant="outlined" color="primary" onClick={() => window.location.href= "/cloud/create-api-key"}><FormattedMessage id="ws-page-api-create_api_key" defaultMessage={"Start the adventure and create the Cloud API key"}/></Button>
                </p>
            </section>
            <section className={styles.featureBlock}>
                <div>
                    <Grid container spacing={5}>
                        <Grid item md={3} xs={6}>
                            <SpeedIcon></SpeedIcon><br/>
                            <FormattedMessage id="ws-page-api-feature-fast" defaultMessage={"Fast"}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <AndroidIcon></AndroidIcon><br/>
                            Android Ready
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <AppleIcon></AppleIcon><br/>
                            IOS Ready
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <HttpsIcon></HttpsIcon><br/>
                            <FormattedMessage id="ws-page-api-feature-secure" defaultMessage={"Secure"}/>
                        </Grid>
                        {/*<Grid item md={3} xs={6}>*/}
                        {/*    <PhoneIcon></PhoneIcon><br/>*/}
                        {/*    <FormattedMessage id="ws-page-api-feature-professional_support" defaultMessage={"Professionel support"}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item md={3} xs={6}>*/}
                        {/*    <EuroSymbolIcon></EuroSymbolIcon><br/>*/}
                        {/*    <FormattedMessage id="ws-page-api-feature-fair_prices" defaultMessage={"Fair per use prices"}/>*/}
                        {/*</Grid>*/}
                        <Grid item md={3} xs={6}>
                            <BackupIcon></BackupIcon><br/>
                            <FormattedMessage id="ws-page-api-feature-online_backup" defaultMessage={"Online Backup"}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <CloudIcon></CloudIcon><br/>
                            <FormattedMessage id="ws-page-api-feature-cloud_in_europe" defaultMessage={"Cloud in Europe"}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <FavoriteIcon></FavoriteIcon><br/>
                            <FormattedMessage id="ws-page-api-feature-made_with_love" defaultMessage={"Made with Love in germany"}/>
                        </Grid>
                        {/*<Grid item md={3} xs={6}>*/}
                        {/*    <FormatPaintIcon></FormatPaintIcon><br/>*/}
                        {/*    <FormattedMessage id="ws-page-api-feature-themes" defaultMessage={"Ready to use Themes"}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item md={3} xs={6}>*/}
                        {/*    <GroupIcon></GroupIcon><br/>*/}
                        {/*    <FormattedMessage id="ws-page-api-feature-big_Community" defaultMessage={"Big community"}/>*/}
                        {/*</Grid>*/}
                        <Grid item md={3} xs={6}>
                            <MonetizationOnIcon></MonetizationOnIcon><br/>
                            <FormattedMessage id="ws-page-api-feature-earn_money" defaultMessage={"Earn money"}/>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className={styles.docsBlock}>
                <p><FormattedMessage id="ws-page-api-feature-getstarted_intro" defaultMessage={"Easy, fast and fair!"}/></p>
                <p><FormattedMessage id="ws-page-api-feature-getstarted_intro_part2" defaultMessage={"Our service can be used from beginners to professionels. Time to inform!"}/></p>
                <p>
                    <Button variant="outlined" onClick={() => window.document.location.href = "/cloud/price"}>
                        <FormattedMessage id="ws-page-api-feature-price" defaultMessage={"Price"} />
                    </Button>
                </p>
            </section>
        </>
    );
};

export default ApiAdvertisementPage;