import * as React from 'react';
import CheckIcon from "@material-ui/icons/Check";

interface FeatureDescriptionProps {
    formattedMessage: React.ReactNode
}

const FeatureDescription: React.FC<FeatureDescriptionProps> = (props) => {
  return (
    <>
        <li>
            <CheckIcon />&nbsp; {props.formattedMessage}
        </li>
    </>
  );
};

export default FeatureDescription;