import * as React from 'react';
import {Hidden} from "@material-ui/core";

interface SliderLegendProps {
    text: string | React.ReactNode;
    color: string;
    degree: string;
    bottom: string;
}

const SliderLegend: React.FC<SliderLegendProps> = (props) => {
    const width = window.innerWidth;
    return (
      <>
          {width > 700 &&
            <Hidden xsDown>
                <p className="legend" style={{fontSize: "20px",bottom: props.bottom, fontWeight: "bold", backgroundColor: props.color, transform: "rotate(-" + props.degree + "deg)"}}>{props.text}</p>
            </Hidden>
          }
      </>
  );
};

export default SliderLegend;