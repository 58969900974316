import * as React from "react";
import {useState} from "react";
import ContentPage from "./component/Content";
import AlertMessage from "./component/AlertMessage";
import LSForm from "./component/LSForm";
import Button from "@material-ui/core/Button/Button";
import RecoverPlsCodeService from "../../service/website/RecoverPlsCodeService";

interface Props {

}

const RecoverProductKeyPage: React.FC<Props> = (props) => {
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const uiSchema = {
        "email": {
            "ui:widget": "email"
        }
    };



    const schema : any = {
        "properties": {
            "email": {
                "title": "PayPal E-Mail",
                "type": "string"
            },
        },
        "required": ["email"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json : any = await RecoverPlsCodeService.recoverPlsKey(data.formData);
        if (json.status === 200) {
            setAlert(<AlertMessage message={"Du hast soeben eine E-Mail erhalten, in der sich der Produktkey befindet."} severity={"success"} />);
            setHideForm(true)
        } else if (json.status === 400) {
            setAlert(<AlertMessage message={"Die PayPal E-Mail Adresse ist uns nicht bekannt. Wurde der Kauf mit einer anderen E-Mail durchgeführt?"} severity={"warning"} />);
        } else {
            setAlert(<AlertMessage message={"Es ist ein unerwarteter Fehler aufgetreten."} severity={"error"} />);
        }
        setIsLoading(false);
    };


    return (
        <>
            <ContentPage title={"Recover product key"} alert={alert} hideContent={hideForm} isLoading={isLoading}>
                <LSForm schema={schema}
                        hide={hideForm}
                        spacing={8}
                        columns={1}
                        uiSchema={uiSchema}
                        onSubmit={(data) => onSubmit(data)}>
                    <Button type={"submit"} variant="contained" color="primary">Produktkey anfordern</Button>
                </LSForm>
            </ContentPage>
        </>
    );
};

export default RecoverProductKeyPage;