import * as React from "react";
import {useState} from "react";
import {useIntl} from "react-intl";
import ContentPage from "../component/Content";

interface Props {

}

const NewsAdministrationPage: React.FC<Props> = (props) => {
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const intl = useIntl();

    const uiSchema = {
        "email": {
            // "ui:help": "Hint: Make it strong!",
            "ui:widget": "email"
        },
        "message": {
            // "ui:help": "Hint: Make it strong!",
            "ui:widget": "textarea"
        }
    };

    const schema: any = {
        "properties": {
            "firstName": {
                "title": intl.formatMessage({id: "ws-page-contact-firstname", defaultMessage: "First name"}),
                "type": "string"
            },
            "lastName": {
                "title": intl.formatMessage({id: "ws-page-contact-lastname", defaultMessage: "Last name"}),
                "type": "string"
            },
            "email": {
                "title": intl.formatMessage({id: "ws-page-contact-email", defaultMessage: "E-Mail"}),
                "type": "string"
            },
            "phone": {
                "title": intl.formatMessage({id: "ws-page-contact-phonenumber", defaultMessage: "Phone number"}),
                "type": "string"
            },
            "message": {
                "title": intl.formatMessage({id: "ws-page-contact-message", defaultMessage: "Message"}),
                "type": "string"
            },
        },
        "required": ["email", "message"]
    };

    const onSubmit = async (data: any) => {

    };


    return (
        <ContentPage title={"News Administration"}
                     hideContent={hideForm}
                     isLoading={isLoading}>
            <p>
                <a href={"/administration/add-news"}>Neue News anlegen</a>
            </p>
        </ContentPage>
    );
};

export default NewsAdministrationPage;