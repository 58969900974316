import * as React from 'react';

interface TextColorProps {
    text: string;
    color: string;
}

const TextColor: React.FC<TextColorProps> = (props) => {
  return (
    <span style={{color: props.color}}>
        {props.text}
    </span>
  );
};

export default TextColor;