import {WS_URL} from "../AbstractService";
import axios from 'axios';

export default class DownloadService {

    static async startDownload(data : any) {
        return axios({
            url: WS_URL+'/download/',
            method: 'POST',
            data: data,
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.version + ".zip"); //or any other extension
            document.body.appendChild(link);
            link.click();
            return response;
        }).catch((error) => {
            return error.response;
        });
    }
}