import * as React from "react";
import {useIntl} from "react-intl";
import ContentPage from "../component/Content";
import CloudPrice from "../component/CloudPrice";

interface Props {

}

const PricePage: React.FC<Props> = (props) => {
    const intl = useIntl();
    return (
        <>
            <ContentPage
                metaTitle={intl.formatMessage({id: "ws-page-price-title", defaultMessage: "Price"})}
                metaDescription={intl.formatMessage({id: "ws-page-price-description", defaultMessage: "Get the prices of our products."})}
                headerImageUrl={"/img/website/header-images/shop-header.webp"}>
                <CloudPrice></CloudPrice>
            </ContentPage>
        </>
    );
};

export default PricePage;