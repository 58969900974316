import * as React from "react";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import CreateIcon from '@material-ui/icons/Create';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PaymentIcon from '@material-ui/icons/Payment';
import LightbulbIcon from '@material-ui/icons/EmojiObjects';

import DownloadIcon from '@material-ui/icons/GetApp';
import styles from './SideMenu.module.css';
import UserService from "../../../service/website/UserService";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import NewspaperIcon from '@material-ui/icons/Note';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import LeaguescriptHeadline from "./LeaguescriptHeadline";


interface Props {
    open?: boolean
    onClose: () => void
}

const menuEntries = (intl: IntlShape) => (
    <div role="presentation">
        <List>
            <ListItem button key={"Startsite"} onClick={() => window.location.href = '/'}>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-startsite" defaultMessage="Startsite"/>} />
            </ListItem>
            <ListItem button key={"News"} onClick={() => window.location.href = '/news'}>
                <ListItemIcon><AnnouncementIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-news" defaultMessage="News"/>} />
            </ListItem>
            {!UserService.isAuthenticated() &&
                <>
                    <ListItem button key={"Register"} onClick={() => window.location.href = '/register'}>
                        <ListItemIcon><CreateIcon /></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-register" defaultMessage="Register"/>} />
                    </ListItem>
                    <ListItem button key={"Login"} onClick={() => window.location.href = '/login'}>
                        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                        <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-login" defaultMessage="Login"/>} />
                    </ListItem>
                </>
            }
            {/*<ListItem button key={"Shop"} onClick={() => window.location.href = '/shop'}>*/}
            {/*    <ListItemIcon><ShoppingCartIcon /></ListItemIcon>*/}
            {/*    <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-shop" defaultMessage="Shop"/>} />*/}
            {/*</ListItem>*/}
            {/*<ListItem button key={"Leagues"} onClick={() => window.location.href = '/products/2'}>*/}
                {/*<ListItemIcon><MailIcon /></ListItemIcon>*/}
                {/*<ListItemText primary={"Ligen"} />*/}
            {/*</ListItem>*/}
        </List>
        <Divider />
        <p className={styles.productHeadline}>
            <LeaguescriptHeadline product={"Cloud Hosting"} />
        </p>
        <br/>
        {/*<ListItem button key={"CloudDocumentation"} onClick={() => window.location.href = '/api'}>*/}
        {/*    <ListItemIcon><MenuBookIcon /></ListItemIcon>*/}
        {/*    <ListItemText primary={"Documentation"} />*/}
        {/*</ListItem>*/}
        <ListItem button key={"GettingStarted"} onClick={() => window.location.href = '/cloud'}>
            <ListItemIcon><PlayArrowIcon /></ListItemIcon>
            <ListItemText primary={intl.formatMessage({id: "ws-component-sidemenu-getting_started", defaultMessage: "Getting Started"})} />
        </ListItem>
        <ListItem button key={"Pricing"} onClick={() => window.location.href = '/cloud/price'}>
            <ListItemIcon><PaymentIcon /></ListItemIcon>
            <ListItemText primary={intl.formatMessage({id: "ws-component-sidemenu-pricing", defaultMessage: "Pricing"})} />
        </ListItem>
        <ListItem button key={"DemoAccount"} onClick={() => window.location.href = 'https://cloud.leaguescript.com/?id=abc'}>
            <ListItemIcon><OndemandVideoIcon /></ListItemIcon>
            <ListItemText primary={intl.formatMessage({id: "ws-component-sidemenu-demo_account", defaultMessage: "Demo Account"})} />
        </ListItem>
        <ListItem button key={"Wiki"} onClick={() => window.location.href = 'http://wiki.leaguescript.com'}>
            <ListItemIcon><MenuBookIcon /></ListItemIcon>
            <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-documentation" defaultMessage={"Documentation"}/>} />
        </ListItem>
        <Divider />
        <List>
            <p className={styles.productHeadline}>
                <LeaguescriptHeadline product={intl.formatMessage({id: "ws-component-sidemenu-self_hosting", defaultMessage: "Self Hosting"})} />
            </p>
            <br/>
            <ListItem button key={"Downloads"} onClick={() => window.location.href = '/downloads'}>
                <ListItemIcon><DownloadIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-download" defaultMessage={"Downloads"}/>} />
            </ListItem>
            <ListItem button key={"Wiki"} onClick={() => window.location.href = 'https://bmsolutions.atlassian.net/wiki/spaces/PLSV13/overview'}>
                <ListItemIcon><MenuBookIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-documentation" defaultMessage={"Documentation"}/>} />
            </ListItem>
            <Divider />
            <ListItem button key={"Newsletter"} onClick={() => window.location.href = '/newsletter'}>
                <ListItemIcon><NewspaperIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-newsletter" defaultMessage={"Newsletter"}/>} />
            </ListItem>
            <ListItem button key={"Send Improvement"} onClick={() => window.location.href = '/improvements'}>
                <ListItemIcon><LightbulbIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-send_improvements" defaultMessage={"Send improvement"}/>} />
            </ListItem>
            <ListItem button key={"Contact"} onClick={() => window.location.href = '/contact'}>
                <ListItemIcon><MailIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="ws-component-sidemenu-contact" defaultMessage={"Contact"}/>} />
            </ListItem>
        </List>
    </div>
);

const SideMenu: React.FC<Props> = (props) => {
    let intl = useIntl();
    return (<>
        <section>
            <Drawer anchor={'left'}
                    onBackdropClick={props.onClose}
                    open={props.open} variant="temporary">
                {menuEntries(intl)}
            </Drawer>
        </section>
    </>);
};

export default SideMenu;