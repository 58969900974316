import * as React from 'react';
import ContentPage from "../component/Content";
import Alert from '@mui/material/Alert/Alert';
import {useIntl} from "react-intl";

interface FinishedProfessionalSupportOrderProps {
}

const FinishedProfessionalSupportOrderPage: React.FC<FinishedProfessionalSupportOrderProps> = (props) => {
    const intl = useIntl();

    const alert = <Alert variant="filled" severity="info">
        <span dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
                id: "ws-page-finishedProfessionalSupportOrder-finished-order-contact-via-email",
                defaultMessage: "Thank you for purchasing our professional support. We will send you a microsoft teams invitation link shortly (within 24 hours). We will then plan a meeting. You should be prepared, so that we can concentrate on your support issue and not on technical meeting details. We are happy to hear you soon."
            })
        }}></span>
    </Alert>;

    return (
        <ContentPage title={"Finished Order"} alert={alert}>
        </ContentPage>
    );
};

export default FinishedProfessionalSupportOrderPage;