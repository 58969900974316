import * as React from "react";
import {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import ContentPage from "../component/Content";
import ApiKeyService from "../../../service/website/ApiKeyService";
import AlertMessage from "../component/AlertMessage";
import styles from "./ApiKeyManagementPage.module.scss";
import Button from "@material-ui/core/Button/Button";
import CloudPrice from "../component/CloudPrice";
import {ApiKey} from "../../../model/ApiKey";
import moment from "moment";

interface Props {

}

const ApiKeyManagementPage: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notAvailable, setNotAvailable] = useState<boolean>(false);
    const [apiKey, setApiKey] = useState<ApiKey>();
    const intl = useIntl();
    const alert = <AlertMessage message={intl.formatMessage({id : "ws-page-apiKeyManagement-no_api_key_message", defaultMessage: "No Api Key created."})} severity={"info"}/>


    const getApiKey = async () => {
        const apiKeyRes = await ApiKeyService.getApiKey();
        if (apiKeyRes.status === 404 || apiKeyRes.status === 500) {
            setNotAvailable(true);
        } else {
            const apiKey = await apiKeyRes.json();
            // @ts-ignore
            setApiKey(apiKey);
        }

    };

    useEffect(() => {
        getApiKey();
        setIsLoading(false);
    }, []);

    return (
        <>
            <ContentPage title={intl.formatMessage({
                                id: "ws-page-apiKeyManagement-headline",
                                defaultMessage: "Your API Key"
                            })}
                         metaTitle={intl.formatMessage({id: "ws-page-apiKeyManagement-title", defaultMessage: "Api Key Management"})}
                         metaDescription={intl.formatMessage({id: "ws-page-apiKeyManagement-description", defaultMessage: "Manage your API Keys."})}
                         headerImageUrl={"/img/website/header-images/api-header.webp"}
                         authRequirement={true}
                         isLoading={isLoading}>
                { notAvailable && alert}
                { !notAvailable &&
                    <>
                        <table className={styles.apikey}>
                            <tr>
                                <td className={styles.label}>API Key:</td>
                                <td>{apiKey?.secretKey}</td>
                            </tr>
                            <tr>
                                <td className={styles.label}>
                                    <FormattedMessage id="ws-page-apiKeyManagement-access_too" defaultMessage={"Access to:"}/>
                                </td>
                                <td>{apiKey?.type === "TRIAL" &&
                                    <FormattedMessage id="ws-page-apiKeyManagement-type_trial" defaultMessage={"Premium Features (7 Day Trial)"}/>
                                }
                                {apiKey?.type === "FREE" &&
                                    <FormattedMessage id="ws-page-apiKeyManagement-type_free" defaultMessage={"Free Tier"}/>
                                }
                                {apiKey?.type === "PAYED" &&
                                    <FormattedMessage id="ws-page-apiKeyManagement-type_payed" defaultMessage={"Premium Features"}/>
                                }
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.label}>
                                    <FormattedMessage id="ws-page-apiKeyManagement-price" defaultMessage={"Price:"}/>
                                </td>
                                <td>{apiKey?.type === "TRIAL" &&
                                    <FormattedMessage id="ws-page-apiKeyManagement-price_trial" defaultMessage={"Free of charge"}/>
                                }
                                {apiKey?.type === "FREE" &&
                                    <FormattedMessage id="ws-page-apiKeyManagement-price_free" defaultMessage={"Free of charge."}/>
                                }
                                {apiKey?.type === "PAYED" &&
                                    <FormattedMessage id="ws-page-apiKeyManagement-price_type_payed" defaultMessage={"19,99 EUR monthly"}/>
                                }
                                </td>
                            </tr>
                            {apiKey?.type === "PAYED" &&
                                <>
                                    <tr>
                                        <td className={styles.label}><FormattedMessage id="ws-page-apiKeyManagement-registered_to"
                                                                                       defaultMessage={"Registered to:"}/></td>
                                        <td>{apiKey?.owner?.firstName} {apiKey?.owner?.lastName} {apiKey?.owner?.email}</td>
                                    </tr>
                                    <tr>
                                        <td className={styles.label}><FormattedMessage id="ws-page-apiKeyManagement-plan_id"
                                                                                       defaultMessage={"Plan ID:"}/></td>
                                        <td>{apiKey?.planId}</td>
                                    </tr>
                                    <tr>
                                        <td className={styles.label}><FormattedMessage id="ws-page-apiKeyManagement-next_billing_time"
                                                                                       defaultMessage={"Next billing time:"}/></td>
                                        <td>{apiKey?.nextBillingTime}</td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <td className={styles.label}><FormattedMessage id="ws-page-apiKeyManagement-payed_until"
                                                                               defaultMessage={"Expires:"}/></td>
                                <td>{moment(apiKey?.payedUntil).locale("de").fromNow()}</td>
                            </tr>
                        </table>
                        <br/>
                        <br/>
                        <div className={styles.actionButtons}>
                            <Button type={"button"}
                                    variant="contained"
                                    onClick={() => window.document.location.href="https://cloud.leaguescript.com/?id=" + apiKey?.secretKey}
                                    color="primary">
                                <FormattedMessage id="ws-page-apiKeyManagement-show_league" defaultMessage={"Open your league"}/>
                            </Button>&nbsp;
                            <Button type={"button"}
                                    variant="contained"
                                    onClick={() => {window.alert("Updates to your API Key may take some minutes.");window.document.location.reload()}}
                                    color="primary">
                                <FormattedMessage id="ws-page-apiKeyManagement-update_api_key" defaultMessage={"Update Api Key Status"}/>
                            </Button>&nbsp;
                            {apiKey?.type === "PAYED" &&
                                <>
                                    <Button type={"button"}
                                        variant="contained"
                                        onClick={() => window.document.location.href = "contact?domain=true&apiKey=" + apiKey.secretKey}
                                        color="primary">
                                    <FormattedMessage id="ws-page-apiKeyManagement-request_https_certificate"
                                                      defaultMessage={"Request https certificate (Configure own domain)"}/>
                                    </Button> &nbsp;
                                    <Button type={"button"}
                                            variant="contained"
                                            onClick={() => {window.document.location.href="https://www.paypal.com/billing/subscriptions"}}
                                            color="primary">
                                        <FormattedMessage id="ws-page-apiKeyManagement-cancelation" defaultMessage={"Cancelation of Abo"}/>
                                    </Button>
                                </>
                            }
                        </div>
                        {apiKey?.type !== "PAYED" &&
                            <CloudPrice withPrice={true} apiKeyType={apiKey?.type} apiKey={apiKey?.secretKey}/>
                        }
                    </>
                }
            </ContentPage>
        </>
    );
};

export default ApiKeyManagementPage;