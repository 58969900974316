import * as React from "react";
import {useState} from "react";
import ContentPage from "./component/Content";
import UserService from "../../service/website/UserService";
import LSForm from "./component/LSForm";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import DownloadChangelogItem from "./component/DownloadChangeLogItem";
import DownloadService from "../../service/website/DownloadService";
import AlertMessage from "./component/AlertMessage";
import {FormattedMessage, useIntl} from "react-intl";
import GlobalErrorAlertMessage from "./component/GlobalErrorAlertMessage";


interface Props {

}

const DownloadsPage: React.FC<Props> = (props) => {
    const intl = useIntl();
    const [alert, setAlert] = useState<JSX.Element | undefined>(<AlertMessage message={UserService.isAuthenticated() ?
        intl.formatMessage({id : "ws-page-downloads-info_message", defaultMessage: "Currently it is only possible to download the Premium League Script. We will have a new free version available in the near future."})
        : intl.formatMessage({id : "ws-page-downloads-not_logged_in_message", defaultMessage: "You musst be logged in to see this page."})} severity={"info"} />);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const schema : any = {
        "properties": {
            "key": {
                "title": intl.formatMessage({id : "ws-page-downloads-product_key_label", defaultMessage: "Productkey"}),
                "type": "string"
            },
            "version": {
                "title": intl.formatMessage({id : "ws-page-downloads-version_label", defaultMessage: "Version"}),
                "type": "string",
                "default": "ls_premium_13_3_0",
                "enum": ["ls_premium_13_3_0", "ls_premium_13_2_0", "ls_premium_13_1_8", "ls_premium_13_1_6", "ls_premium_13_1_0", "ls_premium_13_0_0", "ls_premium_12_9_2", "ls_premium_11_5_3","ls_premium_10_9_0", "ls_premium_9_1_1"],
                "enumNames": ["13.3.0 Stable Release", "13.2.0 Stable Release", "13.1.8 Feature/Bugfix Release", "13.1.6 Feature Release", "13.1.0 Feature Release", "13.0.0 Feature Release", "12.9.2 Bugfix Release", "11.5.3 Bugfix Release", "10.9.0 Feature/Bugfix Release", "9.1.1 Bugfix Release"]
            }
        },
        "required": ["key"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let response : any = await DownloadService.startDownload(data.formData);
        if (response.status === 200) {
        } else if (response.status === 400) {
            setAlert(<AlertMessage message={intl.formatMessage({id : "ws-page-shop-invalid_product_key", defaultMessage: "The product key is not valid. You can request it <a href='/recover-pls-productkey'>here</a> via E-Mail."})} severity={"warning"} />);
            //setAlert(<AlertMessage message={"Der Produktkey ist ungültig. Du kannst <a href=\"/recover-pls-productkey\">hier</a> deinen Key per E-Mail anfordern."} severity={"warning"} />);
        } else {
            setAlert(<GlobalErrorAlertMessage />);
        }
        setIsLoading(false);
    };

    return (
        <>
            {!UserService.isAuthenticated() &&
                <ContentPage title={"Downloads"} alert={alert} hideContent={true}>

                </ContentPage>
            }
            {UserService.isAuthenticated() &&
                <ContentPage title={"Downloads"}
                             alert={alert}
                             isLoading={isLoading}
                             metaTitle={intl.formatMessage({id: "ws-page-download-title", defaultMessage: "Download Area"})}
                             metaDescription={intl.formatMessage({id: "ws-page-download-description", defaultMessage: "Download a version of the PHP Premium League Script."})}
                             headerImageUrl={"/img/website/header-images/download-header.webp"}>
                    <LSForm schema={schema} columns={2} spacing={2} onSubmit={(data) => onSubmit(data)}>
                        <Button type={"submit"} variant="contained" color="primary">
                            <FormattedMessage id="ws-page-downloads-start_download_button" defaultMessage="Start download"/>
                        </Button>&nbsp;
                        <Button onClick={() => window.location.href="/recover-pls-productkey"} variant="contained" color="primary"><FormattedMessage id="ws-page-downloads-request_product_key" defaultMessage="Request productkey"/></Button>
                    </LSForm>
                    <h1>Changelog</h1>
                    <Grid container spacing={1}>
                        <DownloadChangelogItem title={"13.3.0 Stable Release (20.05.2018)"} featureList={
                            <ul>
                                <li>Bugfix: Kleinere Bugfixes</li>
                                <li>Implementiert: Voller https support</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"13.2.0 Stable Release (12.12.2017)"} featureList={
                            <ul>
                                <li>Bugfix: Einige Bugs behoben.</li>
                                <li>Implementiert: Premium Membership check für Ligen und Cups.</li>
                                <li>Implemented: PayPal Membership Plugin Vorbereitung.</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"13.1.8 Feature/Bugfix Release (16.08.2017)"} featureList={
                            <ul>
                                <li>Verbessert: Neuer Installationswizard. Behebt Inkompatibilitäten und vereinfacht die Installation.</li>
                                <li>Verbessert: Neue Installationen können werden jetzt standardmäßig ohne Beispieldaten installiert. Die Beispieldatensätze können aber auf Wunsch am Ende der Installation hinzugefügt werden.</li>
                                <li>Bugfix: Benutzer Registrierung deaktivieren wurde nicht vollständig umgesetzt. Benutzer konnten sich weiterhin registrieren, wenn Sie z.B. einen Cup geöffnet haben.</li>
                                <li>Bugfix: Fehlende News Kategorie führte zu einer falschen -Erfolgreich- Meldung</li>
                                <li>Bugfix: Log Meldungen enthielten bei dem Versuch einem Team beizutreten, mit einem falschen Passwort, nur die ID des Benutzers statt des Namens.</li>
                                <li>Bugfix: Deutsche Übersetzungen. Buchstabendrehen etc. korrigiert.</li>
                                <li>Bugfix: RSS Feed Link gefixt.</li>
                                <li>Verbesserung: Team joinen Link wird nun nicht mehr angezeigt, wenn man dem Team bereits beigetreten ist.</li>
                                <li>Implementiert: Das Team Profil zeigt nun auch die Cups an, an denen das Team teilnimmt.</li>
                                <li>Implementiert: Das User Profil zeigt nun auch die Cups an, an denen der User teilnimmt.</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"13.1.6 Feature Release (30.04.2017)"} featureList={
                            <ul>
                                <li>Official iOS + Android support - App myLeague</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"13.1.6 Feature Release (20.03.2017)"} featureList={
                            <ul>
                                <li>Implementiert: Komplett neu gestaltetes Admin Center! Basierend auf Bootstrap und mobil friendly.</li>
                                <li>Implemetiert: Trnasfermarkt Budget kann jetzt pro Team konfiguriert werden anstelle nur pro Liga. Die Teams werden jetzt mit Bild im Admin Center angezeigt.</li>
                                <li>Implementiert: Es ist jetzt ganz einfach möglich während einer laufenden Virtual Reality League Saison einen Spieler gegen einen anderen zu tauschen.</li>
                                <li>Neuer Technology Stack: Wir haben das neue Admin Center zum Anlass genommen auf neue Technologien zu setzen. Dojo wurde entfernt, stattdessen arbeiten wir jetzt mit Bootstrap, sass und grunt.</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"13.0.0 Feature Release (27.09.2016)"} featureList={
                            <ul>
                                <li>Implementiert: Gelbe, Gelb-Rote und Rote Karten System. Speichere zu jeder Karte den Grund für die Karte, die Minute in der die Karte vergeben wurde und eine Einschätzung ob diese berechtigt war. </li>
                                <li>Implementiert: Gelbe, Gelb-Rote und Rote Karten Statistik pro Liga.</li>
                                <li>Implementiert: Es gibt jetzt einen Spielplan, der die Spieltage sowie die Match Ergebnisse enthält. Du hast somit alle Ergebnisse einer Liga übersichtlich aufgelistet und weißt wo noch Spiele durchzuführen sind.</li>
                                <li>Implementiert: Der Spieltag wird in der Match Detail Seite mit angezeigt.</li>
                                <li>Implementiert: Turniermatches haben jetzt ein Report Feld. Dort kann wie in Ligaspielen auch ein kleiner Bericht zum Match verfasst werden.</li>
                                <li>Implementiert: Die Benutzerliste im Admin Center kann jetzt durchsucht werden um spezielle Benutzer zu finden ohne zu blättern.</li>
                                <li>Implementiert: Einzelspieler Cups können jetzt mit Gruppenphase gespielt werden. Du kannst damit z.B. eine Weltmeisterschaft, Europameisterschaft oder andere Turniere mit Gruppenphase simulieren. Die Generierung der Gruppen geschieht auf Knopfdruck selbstständig.</li>
                                <li>Implementiert: Automatischer Übernahme der Sieger der Gruppenphase in den Turnierbaum mit einem nur einem Klick.</li>
                                <li>Implementiert: Die Gruppenphase kann im Admin Center pro Cup an oder ausgeschaltet werden. Auch die Anzahl der Gruppen ist hier bis zum Start des Turniers jederzeit änderbar.</li>
                                <li>Implementiert: Das Kontrollzentrum des Benutzers wurde um den Bereich 'Meine Spiele' erweitert. Dort kann man übersichtlich alle offenen Matches und Herausforderungen auf einer Seite sehen. </li>
                                <li>Implementiert: Cup checkin. Du kannst jetzt bei jedem Cup eine Zeit angeben, in der die Spieler sich vor dem Turnier als eingeschrieben melden müssen. Dadurch kann der Admin die Spieler die sich Wochen zuvor angemeldet hatten, aber am Tage des Turniers nicht erschienen sind noch vor dme eigentlich Turnier start wieder aus der Liste der Teilnehmer entfernen.</li>
                                <li>Implementiert: Facebook Login integration.</li>
                                <li>Implementiert: Neues aussehen und Codebase für den Turnierbaum.</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"12.9.2 Bugfix Release (18.06.2016)"} featureList={
                            <ul>
                                <li>PHP 7 und Mysql 5.7 Unterstützung hergestellt.</li>
                                <li>Bugfix: Premium Status konnte beim speichern des Team Profils verloren gehen. Diese Version enthält den Hotfix.</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"11.5.3 Bugfix Release (18.06.2016)"} featureList={
                            <ul>
                                <li>Bugfix: Elo Punkte Berechnung gefixt. Die Punkte wurden mehrfach berehcnet, wenn man als Admin ein Spiel editiert hat.</li>
                                <li>Bugfix: Elo Punkte Berechnung gefixt. Durch Doppelposts beim akzeptieren eines Ergebnisses wurden Elo Punkte doppelt berechnet.</li>
                                <li>Bugfix: Premium und Trusted Status verschwindet aus dem Profil eines Users, wenn dieser sein Profil editiert.</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"10.9.0 Feature/Bugfix Release (13.12.2012)"} featureList={
                            <ul>
                                <li>10.9.0 Feature/Bugfix Release</li>
                                <li>Implementiert: Externe Team Liste</li>
                                <li>Implementiert: User und Team Profil Bilder werden in den Liga Tabellen neben den Namen angezeigt.</li>
                                <li>Bugfix: Falsche Zeitangabe bei Letzten Login im Benutzerprofil (12h statt 24h)</li>
                                <li>Bugfix: Eigene Seite: Rubrikname wurde überschrieben, wenn man die allgemeine Konfiguration gespeichert hat.</li>
                                <li>Bugfix: Team Match Forderung erzeugt eine Security Fehlermeldung.</li>
                                <li>Bugfix: Kleinen deutschen Übersetzungsfehler korrigiert "dne" in "den"</li>
                            </ul>
                        }/>
                        <DownloadChangelogItem title={"9.1.1 Bugfix Release (20.02.2012)"} featureList={
                            <ul>
                                <li>Übersetzung von kroatisch wurde von einem kroatisch sprechenden User überarbeitet.</li>
                                <li>Bugfix: News Kommentare können nicht gelöscht werden</li>
                                <li>Bugfix: Blank page bug in der match administration im Cup odul behoben</li>
                                <li>Security Fixes</li>
                                <li>Bugfix im Cup Modul für Team Cups</li>
                                <li>Facebook like Button hat falsche Titel angezeigt.</li>
                                <li>Bugfix: Liga löschen. Es war nicht möglich mit gesetztem Liga Administratoren eine Ligen zu löschen.</li>
                                <li>Bugfix: Tabellen Link in Racing Ligen entfernt</li>
                                <li>Allgemeine Regeln von 4.000 auf 10.000 Zeichen erhöht.</li>
                                <li>Bugfix ps3 Jahresauswahl : Endete 2011</li>
                                <li>Bugfix: HackAttackMail deaktivieren funktionierte nicht</li>
                                <li>Bugfix Cup Modul: Es wurde irrtürmlicherweise angezeigt, dass Turnier Teilnehmer noch nach einem Turnierstart entfernt werden können.</li>
                            </ul>
                        }/>
                    </Grid>
                </ContentPage>
            }
        </>
    );
};

export default DownloadsPage;