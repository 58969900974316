import * as React from 'react';
import HeadlineStartsite from "../component/HeadlineStartsite";
import styles from "./StartsitePage.module.scss";
import ScrollAnimation from "react-animate-on-scroll";

interface FeatureBlockProps {
    backgroundColor: string;
    title: string;
    subtitle: string;
    colorH1: string;
    colorH2: string;
    text: string;
    imageUrl: string;
    imageWidth?: string;
}

const FeatureBlock: React.FC<FeatureBlockProps> = (props) => {
  return (
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div style={{backgroundColor: props.backgroundColor, padding:"20px", minHeight: "550px"}}>
              <HeadlineStartsite title={props.title}
                                 subtitle={props.subtitle}
                                 colorH1={props.colorH1}
                                 colorH2={props.colorH2}
                                 text={props.text}
              />
              <div className={styles.center}>
                  <img className={styles.img} src={props.imageUrl} style={{width: props.imageWidth}} />
              </div>
          </div>
      </ScrollAnimation>
  );
};

export default FeatureBlock;