import {WS_URL} from "../AbstractService";

export default class RecoverPlsCodeService {

    static async recoverPlsKey(data: any) {
        return await fetch(WS_URL + '/recover-product-key', {
            method: 'post',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        });
    }

}