import * as React from "react";
import ContentPage from "../website/component/Content";
import Grid from "@material-ui/core/Grid/Grid";
import Container from "@material-ui/core/Container/Container";
import Box from "@material-ui/core/Box/Box";

interface Props {

}

const NotFoundPage: React.FC<Props> = (props) => {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <Box display="flex" justifyContent={"flex-end"} padding={2}>
                        <img src={"img/main-logo.png"} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ContentPage title={"Diese Seite ist uns leider nicht bekannt. (Fehler 404)"}>
                        <p>Du kommst <a href={"/"}>hier</a> zur Startseite. Solltest du meinen, dass dies ein Fehler ist, kannst du uns eine <a href="/contact">Mitteilung</a> machen.</p>
                    </ContentPage>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFoundPage;