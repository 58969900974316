import * as React from "react";
import Form from "@rjsf/material-ui";
import {FormProps, ObjectFieldTemplateProps} from "@rjsf/core";
import Button from "@material-ui/core/Button/Button";
import styles from './LSForm.module.scss';
import Grid from "@material-ui/core/Grid/Grid";

interface Props extends FormProps<any> {
    submitButtonText?: string
    showSuccessMessage?: boolean
    hide?: boolean
    columns?: number
    spacing?: 0 | 6 | 4 | 3 | 1 | 2 | 5 | 7 | 8 | 9 | 10 | undefined
}

const LSForm: React.FC<Props> = (props) => {
    const getColumns = () => {
        if (window.innerWidth < 700) {
            return 12;
        }
        if (props.columns === 1) {
            return 12;
        }
        if (props.columns === 2) {
            return 6;
        }
        if (props.columns === 3) {
            return 4;
        }
        if (props.columns === 4) {
            return 3;
        }
        // Default one colum
        return 12;
    };

    const getSpacing = (): 0 | 6 | 4 | 3 | 1 | 2 | 5 | 7 | 8 | 9 | 10 | undefined => {
        let spacing: 0 | 6 | 4 | 3 | 1 | 2 | 5 | 7 | 8 | 9 | 10 | undefined = 0;
        if (props.spacing != null) {
            spacing = props.spacing;
        }
        return spacing;
    };

    const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
        return (
            <div>
                <Grid container spacing={getSpacing()}>
                    {props.title}
                    {props.description}
                    {props.properties.map(element => {
                        return <Grid item xs={getColumns()}><div className="property-wrapper">{element.content}</div></Grid>
                    })}
                </Grid>
            </div>
        );
    };

    return (
        <>
            { !props.hide &&
                <Form {...props} showErrorList={false} ObjectFieldTemplate={ObjectFieldTemplate}>
                    { props.children &&
                        <div className={styles.actionBar}>
                            {props.children}
                        </div>
                    }
                    { !props.children &&
                    <div className={styles.actionBar}>
                        <Button type={"submit"}
                                disabled={props.disabled}
                                variant="contained"
                                color="primary">
                            { props.submitButtonText ? props.submitButtonText : "Speichern"}
                                </Button>
                    </div>
                    }
                </Form>
            }

        </>
    );
};

export default LSForm;