import * as React from "react";
import Grid from "@material-ui/core/Grid/Grid";


interface Props {
    title: string;
    featureList: JSX.Element
}

const DownloadChangelogItem: React.FC<Props> = (props) => {
    return (
        <Grid item xs={12}>
            <h3>{ props.title }</h3>
            { props.featureList }
        </Grid>
    );
};

export default DownloadChangelogItem;