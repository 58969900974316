import * as React from "react";
import styles from './Content.module.scss';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {FormattedMessage, useIntl} from "react-intl";
// @ts-ignore
import classNames from "classnames/bind";
import UserService from "../../../service/website/UserService";
import AlertMessage from "./AlertMessage";
import {setTitleAndDescription} from "../../../App";


interface Props {
    title?: string;
    isLoading?: boolean;
    headerImageUrl?: string;
    alert?: JSX.Element | null;
    hideContent?: boolean;
    authRequirement?: boolean;
    metaTitle?: string;
    metaDescription?: string;
    children: React.ReactNode;
}

const ContentPage: React.FC<Props> = (props) => {
    const headerImageStyle = {backgroundImage: `url(${props.headerImageUrl})`};
    const intl = useIntl();
    return (<>
        {
            setTitleAndDescription(
                props.metaTitle ? props.metaTitle : "",
                props.metaDescription ? props.metaDescription : ""
            )
        }
        { props.isLoading ?
            <div className={styles.loader}>
                <CircularProgress /> <br/>
                <FormattedMessage id="ws-component-content-loading" defaultMessage={"Please wait ..."}/>
            </div>:
            <>
                {props.title &&
                    <header>
                        <h1 className={styles.headline}>
                            {props.title}
                        </h1>
                        <hr className={styles.divider}/>
                    </header>
                }
                {props.headerImageUrl &&
                    <div className={styles.headerImage} style={headerImageStyle}>
                    </div>
                }
                {props.alert}
                <div className={classNames(props.hideContent ? styles.hide : "", styles.content, props.alert ? styles.withAlert : "")}>
                    { (props.authRequirement && !UserService.isAuthenticated()) &&
                        <>
                            <AlertMessage message={intl.formatMessage({id: "ws-component-content-please_login_message", defaultMessage: "Please login to get access to this site."})} severity={"error"} />
                        </>
                    }
                    { !(props.authRequirement && !UserService.isAuthenticated()) &&
                        props.children
                    }
                </div>
            </>
        }
    </>);
};

export default ContentPage;