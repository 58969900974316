import {WS_URL} from "../AbstractService";
import LocalStorageService from "./LocalStorageService";

export default class ApiKeyService {

    static async getApiKeys() {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        return await fetch(WS_URL + '/admin/api-key/', {
            method: 'get',
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });
    }

    static async getApiKey() {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        return await fetch(WS_URL + '/api-key', {
            method: 'get',
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });
    }

    static async requestApiKey(data : any) {
        let auth: any = LocalStorageService.getAuthentication();
        if (auth == null) {
            auth = undefined;
        }
        return await fetch(WS_URL + '/api-key/create-api-key', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

}