import * as React from "react";
import {useState} from "react";
import ContentPage from "./component/Content";
import LSForm from "./component/LSForm";
import UserService from "../../service/website/UserService";
import AlertMessage from "./component/AlertMessage";
import {useIntl} from "react-intl";
import {useParams} from "react-router";

interface Props {

}

const ActivateUserPage: React.FC<Props> = (props) => {
    const [extraErrors, setExtraErrors] = useState<any>([]);
    const [alert, setAlert] = useState<JSX.Element | undefined>(undefined);
    const [disableForm, setDisableForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hideForm, setHideForm] = useState<boolean>(false);
    const intl = useIntl();
    const params: any = useParams();

    const schema : any = {
        "properties": {
            "activationCode": {
                "title": intl.formatMessage({id : "ws-page-activateUser-activation-code", defaultMessage: "Activation-Code"}),
                "type": "string",
                "minLength": 7
            }
        },
        "required": ["activationCode"]
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let json = await UserService.activate(data.formData);
        setExtraErrors(json.extraErrors);
        if (json.status === 200) {
            setHideForm(true);
            setAlert(<AlertMessage message={"Dein Account wurde erfolgreich aktiviert. Du kannst dich jetzt einloggen."} severity={"success"} />);
        } else if (json.status === 500) {
            setAlert(<AlertMessage message={"Es ist ein unerwarteter Fehler aufgetreten."} severity={"error"} />);
            setDisableForm(false);
        } else if (json.status === 404) {
            setAlert(<AlertMessage message={"Der Code konnte nicht akiviert werden, weil er nicht gefunden wurde."} severity={"error"} />);
            setDisableForm(false);
        } else {
            setDisableForm(false);
        }
        setIsLoading(false);
    };

    const onError = (e: any) => {
        console.log(e);
    };


    return (
        <ContentPage title={"Benutzer aktivieren"}
                     alert={alert}
                     hideContent={hideForm}
                     isLoading={isLoading}>
            <LSForm schema={schema}
                    hide={hideForm}
                    formData={{activationCode: params.activationCode}}
                    extraErrors={extraErrors}
                    columns={1}
                    disabled={disableForm}
                    onError={onError}
                    submitButtonText={intl.formatMessage({id : "ws-page-activateUser-activate", defaultMessage: "activate"})}
                    onSubmit={(data) => onSubmit(data)}>
            </LSForm>
        </ContentPage>
    );
};

export default ActivateUserPage;